import React from 'react';

import { StepSection } from './StepSection';
import { ActionLink } from './ActionLink';

import auFile from './templates/customer_import_template_au.csv';
import nzFile from './templates/customer_import_template_nz.csv';
import auFileDeprecated from './templates/customer_import_template_au_deprecated.csv';
import nzFileDeprecated from './templates/customer_import_template_nz_deprecated.csv';

import { FEATURE_FLAGS } from 'conf';

const triggerDownload = (region: 'au' | 'nz') => {
  if (region !== 'au' && region !== 'nz') {
    return;
  }
  const link = document.createElement('a');
  if (FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER_UPDATES) {
    link.href = region === 'au' ? auFile : nzFile;
  } else {
    link.href = region === 'au' ? auFileDeprecated : nzFileDeprecated;
  }
  const filename =
    region === 'au'
      ? 'customer_import_template_au.csv'
      : 'customer_import_template_nz.csv';
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleClickAU = () => {
  triggerDownload('au');
};

const handleClickNZ = () => {
  triggerDownload('nz');
};

export const PrepareSection = () => {
  return (
    <StepSection
      index={1}
      title="Prepare for upload"
      description="Download the CSV spreadsheets to fill in the required details."
    >
      <ActionLink
        onClick={handleClickAU}
        text="Download CSV - AU customers"
        icon="download"
      />
      <ActionLink
        onClick={handleClickNZ}
        text="Download CSV - NZ customers"
        icon="download"
      />
    </StepSection>
  );
};
