import {
  GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
  GUARANTOR_IDENTIFICATION_SUBMIT_START,
  GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS,
  GUARANTOR_INFO_LOAD_ERROR,
  GUARANTOR_INFO_LOAD_START,
  GUARANTOR_INFO_LOAD_SUCCESS,
  GUARANTOR_LOGIN_BEGIN,
  GUARANTOR_LOGIN_ERROR,
  GUARANTOR_LOGIN_SUCCESS,
  GUARANTOR_SET_APPROVAL,
  GUARANTOR_SET_GUARANTORFIRSTNAME,
  GUARANTOR_SET_GUARANTORLASTNAME,
  GUARANTOR_SET_REVIEW_INDEX,
  GUARANTOR_SHOW_WARNING,
  GUARANTOR_UPDATE_ERROR,
  GUARANTOR_UPDATE_START,
  GUARANTOR_UPDATE_SUCCESS,
} from './constants';
/* eslint-disable max-lines */
import { setAddress, setIdentityValue } from 'modules/identity/actions';

import api from '../../api';
import { getBase64Image } from 'modules/shared/helpers/convertURLToBase64.js';
import { loadingLiveChat } from 'utils/liveChat.js';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { setToken } from 'modules/shared/auth/actions';
import { getSubject } from 'modules/shared/auth/helpers';
import { removeGuarantorFromState } from 'modules/consumer-onboarding/actions/guarantors';
import { removeGuarantorFromDelete } from 'modules/consumer-onboarding/actions/onboarding';
import { FEATURE_FLAGS } from 'conf';

export function showWarning(warning) {
  return {
    type: GUARANTOR_SHOW_WARNING,
    payload: warning,
  };
}

export function setApproval(value) {
  return (dispatch) => {
    dispatch({
      type: GUARANTOR_SET_APPROVAL,
      payload: value,
    });
  };
}

export function setGuarantorName(key, value) {
  let type;
  switch (key) {
    case 'firstname':
      type = GUARANTOR_SET_GUARANTORFIRSTNAME;
      break;
    case 'lastname':
      type = GUARANTOR_SET_GUARANTORLASTNAME;
      break;
  }
  return {
    type: type,
    payload: value,
  };
}

export function updateGuarantor(id, value, success) {
  return (dispatch, getState) => {
    dispatch({ type: GUARANTOR_UPDATE_START });

    const guarantors = api('guarantors', getState().current_user.access_token);
    let data = {};
    const attributes = { approved: value };

    guarantors.updateGuarantor(
      id,
      attributes,
      (result) => {
        if (result.data.data) {
          data = result.data.data;
        }
        dispatch({
          type: GUARANTOR_UPDATE_SUCCESS,
          payload: data,
          meta: {
            mixpanel: {
              event: 'Guarantor approved',
              props: {
                distinct_id: data.id,
                approved: value,
              },
            },
          },
        });

        success();
      },
      (error) => {
        dispatch({
          type: GUARANTOR_UPDATE_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteGuarantor(id) {
  return (dispatch, getState) => {
    const guarantors = api(
      'guarantors',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    guarantors.delete(id, () => {
      dispatch(removeGuarantorFromState(id));
      dispatch(removeGuarantorFromDelete(id));
    });
  };
}

export function setReviewIndex(index) {
  return {
    type: GUARANTOR_SET_REVIEW_INDEX,
    payload: index,
  };
}

export function loadGuarantorInfo(id) {
  return (dispatch, getState) => {
    dispatch({
      type: GUARANTOR_INFO_LOAD_START,
    });
    if (!FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT) {
      loadingLiveChat('guarantor');
    }

    const guarantors = api('guarantors', getState().current_user.access_token);
    let data = {};

    guarantors.getGuarantor(
      id,
      (result) => {
        if (result.data.data) {
          data = result.data.data;
          mixpanel.identify(data.id);
          mixpanel.register({
            'User ID': data.id,
            email: data.attributes.email,
          });
          mixpanel.people.set({
            $first_name: data.attributes.first_name,
            $last_name: data.attributes.last_name,
            $email: data.attributes.email,
            Role: 'Guarantor',
            $percentage_share: data.attributes.percentage_share,
            $position: data.attributes.position,
          });
        }
        data.application = result.data.included.find(
          (inc) => inc.id === data.relationships.application.data.id
        );
        data.supplier = result.data.included.find(
          (inc) =>
            inc.id !== data.relationships.consumer.data.id &&
            inc.type === 'entities'
        );
        data.consumer = result.data.included.find(
          (inc) => inc.id === data.relationships.consumer.data.id
        );
        data.guarantors = result.data.included.filter(
          (inc) => inc.type === 'guarantors'
        );
        data.trade_references = result.data.included.filter(
          (inc) => inc.type === 'trade_references' && !inc.attributes.archived
        );
        data.people = result.data.included.filter(
          (inc) => inc.type === 'people'
        );

        data.signatures = result.data.included.filter((inc) => {
          return inc.type === 'signatures';
        });

        if (data.signatures.length) {
          dispatch(loadGuarantorSignature(data.signatures));
        }

        //
        // Addresses
        //
        const physicalAddressId =
          data.application.attributes.physical_address_id;
        data.physical_address = result.data.included.find(
          (includedData) =>
            includedData.type === 'addresses' &&
            includedData.id === physicalAddressId
        );

        const postalAddressId = data.application.attributes.postal_address_id;
        if (postalAddressId) {
          data.postal_address = result.data.included.find(
            (includedData) =>
              includedData.type === 'addresses' &&
              includedData.id === postalAddressId
          );
        }

        data.addon_answers = result.data.included.filter(
          (inc) => inc.type === 'addon_answers'
        );
        data.addon_rules = result.data.included.filter(
          (inc) => inc.type === 'addon_rules'
        );
        data.payments = result.data.included.filter(
          (inc) => inc.type === 'payments'
        );

        dispatch({
          type: GUARANTOR_INFO_LOAD_SUCCESS,
          payload: data,
          meta: {
            mixpanel: {
              event: 'Loading Guarantor',
              props: {
                distinct_id: data.id,
                Application_id: data.application.id,
                firstame: data.attributes.first_name,
                Lastname: data.attributes.last_name,
                email: data.attributes.email,
                percentage_share: data.attributes.percentage_share,
                position: data.attributes.position,
              },
            },
          },
        });
      },
      (error) => {
        dispatch({
          type: GUARANTOR_INFO_LOAD_ERROR,
          payload: error,
        });
      },
      {
        params: {
          include:
            'application,trade_references,application.guarantors,application.supplier,consumer,consumer.people,application.physical_address,application.postal_address,application.addon_answers,application.addon_rules,application.payments',
        },
      }
    );
  };
}

export function guarantorLogin(guarantor, password, success) {
  return (dispatch, getState) => {
    var tokens = api('tokens');
    dispatch({ type: GUARANTOR_LOGIN_BEGIN });

    tokens.requestToken(
      {
        auth: {
          guarantor: guarantor,
          password: password,
        },
      },
      (result) => {
        const token = result.data.jwt;

        // Add token to redux.
        setToken(token);

        const id = getSubject(token);
        // Update state
        dispatch({
          type: GUARANTOR_LOGIN_SUCCESS,
          payload: result.data,
          meta: {
            mixpanel: {
              event: 'Log in',
              props: {
                distinct_id: id,
              },
            },
          },
        });
        success();
      },
      (error) => {
        dispatch({
          type: GUARANTOR_LOGIN_ERROR,
          meta: {
            mixpanel: {
              event: 'Log in failed',
              props: {
                Login_error: error,
              },
            },
          },
        });
      }
    );
  };
}

export function submitIdentification(id, success, overrides = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: GUARANTOR_IDENTIFICATION_SUBMIT_START,
    });
    const signatures = api('signatures', getState().current_user.access_token);
    let section_state = getState().identity;
    let dob = overrides.dob || section_state.dob;
    let formattedDate = moment(
      dob.month + '/' + dob.day + '/' + dob.year,
      'MM/DD/YYYY'
    ).format('YYYY-MM-DD');
    let expDate = section_state.identification_exp_date;
    let formattedIdExpDate = moment(
      expDate.month + '/' + expDate.day + '/' + expDate.year,
      'MM/DD/YYYY'
    ).format('YYYY-MM-DD');

    let attributes = {
      first_name: overrides.first_name || section_state.first_name,
      last_name: overrides.last_name || section_state.last_name,
      dob: formattedDate,
      identification_type: overrides.identification_type
        ? overrides.identification_type
        : section_state.noIdentification
        ? 'no_identification'
        : section_state.type,
      identification_number: section_state.noIdentification
        ? null
        : section_state.number,
      identification_version:
        !section_state.noIdentification &&
        section_state.type === 'driver_licence'
          ? section_state.driver_licence_version
          : null,
      identification_state:
        !section_state.noIdentification &&
        section_state.type === 'driver_licence'
          ? section_state.identification_state
          : null,
      identification_expiry_date:
        !section_state.noIdentification && section_state.type === 'passport'
          ? formattedIdExpDate
          : null,
      identification_image: section_state.image_64
        ? section_state.image_64
        : null,
      no_identification_reason: overrides.no_identification_reason
        ? overrides.no_identification_reason
        : section_state.image_64
        ? null
        : section_state.no_identification_reason,
      region: section_state.region,
      other_region: section_state.other_region,
    };

    let signature_id = getState().guarantor.data.attributes.signature_id;
    let signatureAddress = section_state.address;
    let addresses = api('addresses', getState().current_user.access_token);

    if (signature_id) {
      addresses.createAddress(signatureAddress, (result) => {
        let addressId = result.data.data.id;

        attributes.address_id = addressId;

        signatures.updateSignature(
          signature_id,
          attributes,
          (result) => {
            dispatch({
              type: GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS,
            });
            success();
          },
          (error) => {
            dispatch({
              type: GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
              payload: error,
            });
          },
          (error) => {
            dispatch({
              type: GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
              payload: error,
            });
          }
        );
      });
    } else {
      addresses.createAddress(
        signatureAddress,
        (result) => {
          let addressId = result.data.data.id;

          attributes.address_id = addressId;

          signatures.createSignatureForGuarantor(
            id,
            attributes,
            (result) => {
              dispatch({
                type: GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS,
              });
              success();
            },
            (error) => {
              dispatch({
                type: GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
                payload: error,
              });
            }
          );
        },
        (error) => {
          dispatch({
            type: GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
            payload: error,
          });
        }
      );
    }
  };
}

export function submitNoIdentification(id, success) {
  return (dispatch, getState) => {
    dispatch({
      type: GUARANTOR_IDENTIFICATION_SUBMIT_START,
    });
    let signatures = api('signatures', getState().current_user.access_token);
    let firstname = getState().guarantor.data.attributes.first_name;
    let lastname = getState().guarantor.data.attributes.last_name;

    let attributes = {
      first_name: firstname,
      last_name: lastname,
      identification_type: 'no_identification',
      no_identification_reason: 'not_required',
    };

    let signature_id = getState().guarantor.data.attributes.signature_id;
    if (signature_id) {
      signatures.updateSignature(
        signature_id,
        attributes,
        (result) => {
          dispatch({
            type: GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS,
          });
          success();
        },
        (error) => {
          dispatch({
            type: GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
            payload: error,
          });
        }
      );
    } else {
      signatures.createSignatureForGuarantor(
        id,
        attributes,
        (result) => {
          dispatch({
            type: GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS,
          });
          success();
        },
        (error) => {
          dispatch({
            type: GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
            payload: error,
          });
        }
      );
    }
  };
}

export function loadGuarantorSignature(signatures) {
  return (dispatch, getState) => {
    let signature = signatures.find((s) => {
      return s.attributes.identification_type !== 'no_identification';
    });
    if (!signature) {
      signature = signatures[0];
    }

    const dob = (signature.attributes.dob || '--').split('-');
    dispatch(
      setIdentityValue('dob', {
        month: parseInt(dob[1]),
        day: dob[2],
        year: dob[0],
      })
    );
    dispatch(setIdentityValue('first_name', signature.attributes.first_name));
    dispatch(setIdentityValue('last_name', signature.attributes.last_name));
    dispatch(
      setIdentityValue('type', signature.attributes.identification_type)
    );
    dispatch(
      setIdentityValue('number', signature.attributes.identification_number)
    );
    if (signature.attributes.identification_type === 'driver_licence') {
      dispatch(
        setIdentityValue(
          'driver_licence_version',
          signature.attributes.identification_version
        )
      );
    } else if (signature.attributes.identification_type === 'passport') {
      const expDate = (
        signature.attributes.identification_expiry_date || '--'
      ).split('-');
      dispatch(
        setIdentityValue('identification_exp_date', {
          month: parseInt(expDate[1]),
          day: expDate[2],
          year: expDate[0],
        })
      );
    }

    if (signature.attributes.no_identification_reason) {
      dispatch(
        setIdentityValue(
          'noIdentificationReason',
          signature.attributes.no_identification_reason
        )
      );
    }

    let imageUrl;
    const identificationImage = signature.attributes.identification_image;
    if (identificationImage && identificationImage.identification_image) {
      imageUrl = identificationImage.identification_image.url;
    }

    let backImageUrl;
    const identificationBackImage =
      signature.attributes.identification_back_image;
    if (
      identificationBackImage &&
      identificationBackImage.identification_image
    ) {
      backImageUrl = identificationBackImage.identification_image.url;
    }

    if (imageUrl) {
      getBase64Image(imageUrl, (image64) => {
        dispatch(setIdentityValue('image_64', image64));
      });
    }

    if (backImageUrl) {
      getBase64Image(backImageUrl, (back_image_64) => {
        dispatch(setIdentityValue('back_image_64', back_image_64));
      });
    }

    if (signature.attributes.address_id) {
      const addresses = api('addresses', getState().current_user.access_token);

      addresses.getAddress(
        signature.attributes.address_id,
        (address) => {
          const data = address.data.data;

          dispatch(
            setAddress({
              api_id: data.attributes.api_id,
              api_provider: data.attributes.api_provider,
              full_address: data.attributes.full_address,
              raw_data: data.attributes.raw_data,
            })
          );
        },
        () => {
          // do nothing
        }
      );
    }

    if (
      signature.attributes.region === 'NZ' ||
      signature.attributes.region === 'AU'
    ) {
      dispatch(setIdentityValue('region', signature.attributes.region));
      dispatch(setIdentityValue('other_region', false));
    } else {
      dispatch(setIdentityValue('region', ''));
      dispatch(setIdentityValue('other_region', true));
    }
  };
}
