import Modal from 'modules/shared/components/v2/Modal';
import React from 'react';
import Button from 'modules/shared/components/inputs/Button';

import styled from 'styled-components';

const ModalWrapper = styled.div`
  .report-fraudulent-modal {
    .modal-card {
      width: 380px;
      padding-bottom: 20px;
    }

    .modal-card-head {
      display: block;
      padding-top: 30px;
      padding-bottom: 0;
      button.delete {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    .modal-card-title {
      text-align: center;
    }

    .modal-card-body {
      padding: 20px;
      line-height: 175%;
    }

    .modal-button__cancel {
      background-color: white;
      border: 2px solid var(--main-color);
      color: var(--main-color);
    }

    .modal-card-foot {
      padding-bottom: 30px;
    }
  }
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2em;
`;

function ReportFraudulentAccountModal({ onClose, onConfirm }) {
  return (
    <ModalWrapper>
      <Modal
        onClose={onClose}
        rootClassName="report-fraudulent-modal"
        title="Report fraudulent"
        footer={
          <ModalButtonWrapper>
            <Button handleClick={onConfirm} text="Confirm" type="button" />
            <Button
              className="modal-button__cancel"
              handleClick={onClose}
              text="Cancel"
              type="button"
            />
          </ModalButtonWrapper>
        }
      >
        Once confirmed, any email addresses attached to this VCF will be blocked
        from the entire 1Centre database. All linked applications in the Alert
        section will be automatically unlinked. Please contact{' '}
        <a href="mailto:support@1centre.com">support@1centre.com</a> if you
        require further assistance.
      </Modal>
    </ModalWrapper>
  );
}

export default ReportFraudulentAccountModal;
