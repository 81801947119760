import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding-left: 45px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`;

export const HeaderContents = styled.div``;

export const HeaderLogo = styled.img`
  width: 250px;
`;

export const HeaderLogoContainer = styled.div`
  justify-self: flex-end;
`;

export const H4Title = styled.h4`
  font-size: 18px !important;
  margin: 1em 0;
  padding-left: 45px;
`;

export const HeaderCompanyName = styled.p`
  font-size: 18px !important;
`;

export const ReportBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 0;
`;

export const ReportContainer = styled.div`
  padding: 2em;
`;

export const SummaryMessageContainer = styled.div`
  margin: 2em auto;
  p {
    font-weight: 500 !important;
  }
`;

export const SummaryTableContainer = styled.div`
  max-width: 400px;
`;

export const SummaryContentContainer = styled.div`
  padding-left: 50px;
`;

export const SummaryInformationTitle = styled.h4`
  font-size: 18px !important;
  margin: 1em 0;
`;

export const CurrentShareholdersTableWrapper = styled.div`
  margin: 1em 0;
`;

export const SectionContentContainer = styled.div`
  margin-bottom: 2em;
`;

export const BoldSpanText = styled.p`
  span {
    font-weight: 500 !important;
  }
`;

export const PPSRSummaryTextContainer = styled.div`
  margin-bottom: 2em;
`;
