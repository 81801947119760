import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import IconReviewDate from 'images/svgs/icon-review-date.svg';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import styles from 'modules/shared/components/inputs/css/BorderedCalendarPicker.css';
import TextInput from 'modules/shared/components/inputs/TextInput';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

const CalendarCloseBtn = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-flex;
  font-size: 1.125rem;
  margin: 0;
  padding: 3px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

const PopoverContainer = styled(Popover)`
  top: 35px !important;
`;

const allowedCharsRegex = /^[0-9\/]*$/;

const formatInputDate = (value) => {
  const numericValue = value.replace(/[^\d]/g, ''); // Remove all non-numeric characters
  let formattedValue = numericValue.slice(0, 2); // Day part
  if (numericValue.length > 2) {
    formattedValue += '/' + numericValue.slice(2, 4); // Month part
  }
  if (numericValue.length > 4) {
    formattedValue += '/' + numericValue.slice(4, 8); // Year part
  }
  return formattedValue;
};

const validateDay = (day, month, year) => {
  const date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD', true);
  return date.isValid() && date.date() === parseInt(day, 10);
};

const validateMonth = (month) => {
  return month >= 1 && month <= 12;
};

function formatDate(date) {
  if (date) {
    return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }

  return '';
}

function toDateObject(date) {
  if (date === null || typeof date === 'undefined') {
    return null;
  }

  if (moment.isDate(date)) {
    return date;
  }

  if (moment.isMoment(date)) {
    return date.toDate();
  }

  const formattedDate = moment(date);
  if (formattedDate.isValid()) {
    return formattedDate.toDate();
  }

  return moment(date, 'DD/MM/YYYY').toDate();
}

function useCalendarState(props) {
  const { isDisabled, isStyledUnderlined, onChange, value } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(value);

  const onOpenCalendar = (event) => {
    if (isDisabled) {
      return;
    }

    setAnchorEl(
      event.target.parentElement.parentElement || event.currentTarget
    );
  };

  const onCloseCalendar = () => {
    if (isDisabled) {
      return;
    }

    setAnchorEl(null);
  };

  const onSelectDate = (date, isStyledUnderlined = false) => {
    if (isDisabled || !moment(date).isValid()) {
      return;
    }

    setSelectedDate(date);
    onCloseCalendar();

    if (onChange) {
      if (isStyledUnderlined) {
        return onChange(formatDate(date));
      }

      onChange(date);
    }
  };

  const onResetDate = () => {
    setSelectedDate(null);

    if (onChange) {
      onChange(null);
    }
  };

  const isCalendarVisible = Boolean(anchorEl);
  const calendarValue = toDateObject(selectedDate);

  return {
    anchorEl,
    calendarValue,
    isCalendarVisible,
    onCloseCalendar,
    onOpenCalendar,
    onResetDate,
    onSelectDate,
    selectedDate,
  };
}

function ResetDate(props) {
  const { onResetDate, value, resetInput } = props;

  const onClick = (event) => {
    event.stopPropagation();

    onResetDate();
    resetInput();
  };

  if (value) {
    return (
      <Fragment>
        <CalendarCloseBtn type="button" onClick={onClick}>
          <CloseIcon fontSize="small" />
        </CalendarCloseBtn>
      </Fragment>
    );
  }

  return null;
}

function EndAdornment(props) {
  const {
    disableReset,
    isDisabled,
    onCalendarIconClick,
    onResetDate,
    value,
    resetInput,
  } = props;

  if (isDisabled) {
    return null;
  }

  return (
    <InputAdornment
      classes={{
        root: styles.calendar_icon,
      }}
      position="end"
    >
      {disableReset ? (
        ''
      ) : (
        <ResetDate
          value={value}
          onResetDate={onResetDate}
          resetInput={resetInput}
        />
      )}
      <IconReviewDate type="button" onClick={onCalendarIconClick} />
    </InputAdornment>
  );
}

export default function BorderedCalendarPicker(props) {
  const {
    disableReset,
    isDisabled,
    isStyledUnderlined,
    label,
    labelShrink,
    maxDate,
    minDate,
    onBlur,
    required,
    textFieldProps,
    value,
    defaultValue,
  } = props;

  const {
    anchorEl,
    calendarValue,
    isCalendarVisible,
    onCloseCalendar,
    onOpenCalendar,
    onResetDate,
    onSelectDate,
  } = useCalendarState(props);

  const currentPersistedValue = value || defaultValue;

  const [inputValue, setInputValue] = useState(
    currentPersistedValue ? formatDate(currentPersistedValue) : null
  );
  const [inputError, setInputError] = useState('');

  const handleKeyDown = (e) => {
    if (
      inputValue &&
      inputValue.length === 10 &&
      ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(e.key)
    ) {
      setInputValue('');
    }
  };

  const handleInputChange = (e) => {
    const currentValue = e.target.value;

    if (!allowedCharsRegex.test(currentValue)) {
      return;
    }

    const formattedValue = formatInputDate(currentValue);
    setInputValue(formattedValue);

    if (formattedValue.length === 10) {
      validateInput(formattedValue);
    }
  };

  const checkParsedDateIsValid = (parsedDate) => {
    const isValidParsedDate = parsedDate.isValid();
    if (!isValidParsedDate) return false;

    //For some reason the isSameOrAfter and isSameOrBefore doesn't behave properly
    const isWithinMaxDate = maxDate
      ? !parsedDate.isAfter(moment(maxDate, 'DD/MM/YYYY').add(1, 'days'))
      : true;

    const isWithinMinDate = minDate
      ? !parsedDate.isBefore(moment(minDate, 'DD/MM/YYYY').subtract(1, 'days'))
      : true;

    if (isWithinMaxDate && isWithinMinDate) {
      setInputError('');
      return true;
    }

    if (maxDate && !minDate) {
      setInputError(`Please enter a date prior ${formatDate(maxDate)} `);
      return false;
    }
    if (minDate && !maxDate) {
      setInputError(`Please enter a date after ${formatDate(minDate)}`);
      return false;
    }

    setInputError(
      `Please enter a date within ${formatDate(minDate)} - ${formatDate(
        maxDate
      )}`
    );
    return false;
  };

  const validateInput = (input, fromBlur = false) => {
    const parts = input.split('/');
    const [day, month, year] = parts;

    if (day && month && year) {
      if (!validateMonth(month)) {
        setInputError('Invalid date');
        fromBlur && resetInput();
      } else if (!validateDay(day, month, year)) {
        setInputError('Invalid date');
        fromBlur && resetInput();
      } else {
        const parsedDate = moment(input, 'DD/MM/YYYY', true);
        if (checkParsedDateIsValid(parsedDate)) {
          onSelectDate(parsedDate);
          resetInput();
        } else {
          fromBlur && resetInput();
        }
      }
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    validateInput(e.target.value, true);
  };

  const resetInput = () => {
    setInputError('');
    setInputValue(null);
  };

  const displayValue =
    inputValue === null
      ? calendarValue
        ? formatDate(calendarValue)
        : currentPersistedValue
        ? formatDate(currentPersistedValue)
        : ''
      : inputValue;

  const { error: textFieldError, helperText } = textFieldProps || {};
  const textFieldHelperText = textFieldError ? helperText : '';

  let textInput = (
    <BorderedTextField
      customProps={{
        endAdornment: (
          <EndAdornment
            isDisabled={isDisabled}
            onCalendarIconClick={onOpenCalendar}
            onResetDate={onResetDate}
            disableReset={disableReset}
            value={value}
            resetInput={resetInput}
          />
        ),
      }}
      disabled={isDisabled}
      label={label}
      labelShrink={labelShrink}
      required={required}
      value={displayValue}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      placeholder="DD/MM/YYYY"
      error={!!inputError || textFieldError}
      helperText={inputError || textFieldHelperText}
      {...textFieldProps}
    />
  );

  if (isStyledUnderlined) {
    textInput = (
      <TextInput
        label={label}
        EndAdornment={
          <EndAdornment
            onCalendarIconClick={onOpenCalendar}
            onResetDate={onResetDate}
            value={value}
            isDisabled={isDisabled}
            resetInput={resetInput}
          />
        }
        disabled={isDisabled}
        value={displayValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder="DD/MM/YYYY"
        required={required}
        {...textFieldProps}
        error={inputError || textFieldError}
        helperText={inputError || textFieldHelperText}
      />
    );
  }

  return (
    <div>
      {textInput}
      <PopoverContainer
        open={isCalendarVisible}
        onClose={onCloseCalendar}
        anchorEl={anchorEl}
      >
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          value={calendarValue || new Date()}
          onClickDay={(value) => {
            setInputValue(null);
            setInputError('');
            onSelectDate(value, isStyledUnderlined);
          }}
        />
      </PopoverContainer>
    </div>
  );
}

BorderedCalendarPicker.defaultProps = {
  isDisabled: false,
  isStyledUnderlined: false,
};
