import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getCompanyInfo } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';

const CompanyInformationContent = ({ companyInfo }) => {
  const {
    RegisteredName,
    TradingName,
    AddressRegisteredOffice,
    PreviousRegisteredNames,
    PreviousTradingNames,
    UltimateHoldingCompany,
  } = companyInfo;
  return (
    <SectionContentContainer>
      <div className={styles.row}>
        <div className={styles.half_col}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Registered Name
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {RegisteredName || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Trading Name
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {TradingName || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Address - Registered Office
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {AddressRegisteredOffice || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Previous Registered Names
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {PreviousRegisteredNames
                    ? PreviousRegisteredNames.join(', ')
                    : '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Previous Trading Names
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {PreviousTradingNames ? PreviousTradingNames.join(', ') : '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Ultimate Holding Company
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {UltimateHoldingCompany || '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SectionContentContainer>
  );
};

const CompanyInformation = ({ isAllExpanded, data }) => {
  const companyInfo = getCompanyInfo(data);
  const hasSectionData = checkHasSectionData(companyInfo);

  return (
    <ReviewContainer
      subHeading="Company Information"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CompanyInformationContent companyInfo={companyInfo} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default CompanyInformation;
