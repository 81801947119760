import React from 'react';

import styles from '../centrix.css';
import scoreStyles from '../../../../css/CommonReviewBusiness.css';
import scoreGraphStyles from '../../../australia/equifax/css/Score.css';
import moment from 'moment';
import get from 'lodash.get';
import sum from 'lodash.sum';
import isBlank from 'utils/isBlank';
import objectToArray from 'utils/objectToArray';

const Icon = ({ isSuccess }) => {
  if (isSuccess) {
    return (
      <span className="icon has-text-success mr-1">
        <i className="fas fa-check-circle"></i>
      </span>
    );
  }

  return (
    <span className="icon has-text-danger mr-1">
      <i className="fas fa-exclamation-circle"></i>
    </span>
  );
};

const INSOLVENCY_NAMES_WITH_VALUE = [
  'TotalCurrentBankruptcies',
  'TotalCurrentSIO',
  'TotalCurrentNAP',
  'TotalCurrentOther',
  'TotalDischargedInsolvencies',
];

const extractValuePairs = (summaryItems, summaryItemType) => {
  const itemsByType =
    summaryItems.find((item) => item.summary_item_type === summaryItemType) ||
    {};

  const valuePairs = get(itemsByType, 'name_value_pairs.name_value_pair', []);
  return objectToArray(valuePairs);
};

const getTotalInsolvencies = (summaryItems) => {
  const valuePairs = extractValuePairs(summaryItems, 'Insolvencies');
  if (isBlank(valuePairs)) {
    return 0;
  }

  const relevantInsolvencyMetrics = valuePairs.filter((item) =>
    INSOLVENCY_NAMES_WITH_VALUE.includes(item.name)
  );
  const metrics = relevantInsolvencyMetrics.map((item) => parseInt(item.value));

  return sum(metrics);
};

const getTotalDefaults = (summaryItems) => {
  const valuePairs = extractValuePairs(summaryItems, 'Defaults');
  if (isBlank(valuePairs)) {
    return 0;
  }

  const totalDefaults =
    valuePairs.find((item) => item.name === 'TotalDefaults') || {};

  return parseInt(get(totalDefaults, 'value', 0));
};

const getTotalJudgments = (summaryItems) => {
  const valuePairs = extractValuePairs(summaryItems, 'Judgments');

  if (isBlank(valuePairs)) {
    return 0;
  }

  const totalJudgements =
    valuePairs.find((item) => item.name === 'TotalJudgments') || {};

  return parseInt(get(totalJudgements, 'value', 0));
};

const getTotalCompanyAffiliations = (summaryItems) => {
  const valuePairs = extractValuePairs(summaryItems, 'DirectorAffiliations');

  if (isBlank(valuePairs)) {
    return 0;
  }

  const totalCurrentDirectorships = valuePairs.find(
    (item) => item.name === 'TotalCurrentDirectorships'
  );

  return parseInt(get(totalCurrentDirectorships, 'value', 0));
};

const getTotalPreviousEnquiries = (summaryItems) => {
  const valuePairs = extractValuePairs(summaryItems, 'PreviousEnquiries');

  if (isBlank(valuePairs)) {
    return 0;
  }

  const totalPreviousEnquiries = valuePairs.find(
    (item) => item.name === 'TotalPreviousEnquiries'
  );

  return parseInt(get(totalPreviousEnquiries, 'value', 0));
};

class SummaryInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  _scoreGraph(score) {
    let score_masterscale = score,
      maxValue = 1000,
      minValue = 0,
      valuePosition = minValue;

    if (score_masterscale < minValue) score_masterscale = minValue;
    valuePosition =
      (
        ((score_masterscale - minValue) / (maxValue - minValue)) *
        100
      ).toString() + '%';

    return (
      <div className={scoreStyles.overview + ' pt-6 px-6 mb-4'}>
        <div className={scoreStyles.overview_rating_full}>
          <div className={scoreStyles.liner}>
            <div className={scoreStyles.overview_rating_graph}>
              <div className="has-text-centered is-size-3">
                <span className="has-text-weight-semibold">
                  {score_masterscale}
                </span>
                <span className="has-text-dark"> / {maxValue}</span>
              </div>

              <div
                className={
                  styles.overview_rating_indicator +
                  ' is-size-2 has-text-warning has-text-weight-bold'
                }
                style={{ left: valuePosition }}
              />

              <div
                className={scoreGraphStyles.overview_rating_graph_bg_reverse}
              />
              <span className={styles.overview_rating_graph_minus_20_label}>
                <strong>HIGH RISK</strong>
              </span>
              <span className={styles.overview_rating_graph_120_label}>
                <strong>LOW RISK</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { data_sets } = data;

    const summaryItems = get(data, 'data_sets.summary_items.summary_item', []);
    const totalInsolvencies = getTotalInsolvencies(summaryItems);
    const totalDefaults = getTotalDefaults(summaryItems);
    const totalJudgements = getTotalJudgments(summaryItems);
    const totalCompanyAffiliations = getTotalCompanyAffiliations(summaryItems);
    const totalPreviousEnquiries = getTotalPreviousEnquiries(summaryItems);

    const score =
      data_sets.extra_data_items.extra_data_item.name_value_pairs.name_value_pair.find(
        (obj) => obj.name === 'Score'
      ).value;
    const riskOdds =
      data_sets.extra_data_items.extra_data_item.name_value_pairs.name_value_pair.find(
        (obj) => obj.name === 'GBOdds'
      ).value;

    const isDriverLicenceVerified = data_sets.driver_licence_verification;

    return (
      <div>
        <div className={styles.row}>
          <div className={styles.col_5}>
            <div className={styles.table_container}>
              <table className={`${styles.table} ${styles.table_no_border}`}>
                <tbody>
                  <tr>
                    <td>
                      <span className="ml-5 has-text-weight-medium">
                        Age of File:
                      </span>
                    </td>
                    <td>
                      {moment(data_sets.consumer_file.age_of_file).format(
                        'DD/MM/YYYY'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Icon isSuccess={totalJudgements === 0} />
                      <span className="has-text-weight-medium">
                        Judgements:
                      </span>
                    </td>
                    <td>{totalJudgements}</td>
                  </tr>
                  <tr>
                    <td>
                      <Icon isSuccess={totalInsolvencies === 0} />
                      <span className="has-text-weight-medium">
                        Insolvency Notices:
                      </span>
                    </td>
                    <td>{totalInsolvencies}</td>
                  </tr>
                  <tr>
                    <td>
                      <Icon isSuccess={totalDefaults === 0} />
                      <span className="has-text-weight-medium">
                        Credit Defaults:
                      </span>
                    </td>
                    <td>{totalDefaults}</td>
                  </tr>
                  {isDriverLicenceVerified && (
                    <tr>
                      <td>
                        <Icon isSuccess={true} />
                        <span className="has-text-weight-medium">
                          Driver Licence Verification:
                        </span>
                      </td>
                      <td>Yes</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <span className="ml-5 has-text-weight-medium">
                        Company Affiliations:
                      </span>
                    </td>
                    <td>{totalCompanyAffiliations}</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="ml-5 has-text-weight-medium">
                        File Activity:
                      </span>
                    </td>
                    <td>{totalPreviousEnquiries}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.col_7}>
            {this._scoreGraph(score)}
            <div className={`${styles.row} pt-6 has-text-centered`}>
              <p>
                <span className="is-size-5 has-text-weight-semibold is-size-4">
                  Risk Odds: {riskOdds}
                </span>
              </p>
              <p className="has-text-weight-semibold is-size-5 mb-2">
                General information about this score:
              </p>
              <p className="is-size-6 mb-1">
                The higher the score the lower the risk
              </p>
              <p className="is-size-6">
                A score in this range indicates you can expect 6<br /> good
                accounts for every 1 bad performing account
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryInformation;
