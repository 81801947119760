import { FEATURE_FLAGS } from 'conf';
import isEmpty from 'lodash.isempty';
import GuarantorModel from 'models/GuarantorModel';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { Fragment } from 'react';
import { capitalize } from 'utils/formatting';

const getGuarantorFieldsFromSignature = (guarantors, signatures) => {
  return guarantors.map((guarantor) => {
    const { signatureId } = guarantor;

    const guarantorSignature = signatures.find(
      (signature) => signature.id === signatureId
    );

    if (guarantorSignature) {
      const { contactPhoneNumber, contactCountryCode, residentialType } =
        guarantorSignature || {};
      const newGuarantor = { ...guarantor.data };
      newGuarantor.attributes.contact_phone_number = contactPhoneNumber;
      newGuarantor.attributes.contact_country_code = contactCountryCode;
      newGuarantor.attributes.residential_type = residentialType;

      return new GuarantorModel(newGuarantor);
    }

    return guarantor;
  });
};

function Guarantor(props) {
  const { guarantor, index } = props;
  const { contactCountryCode, contactPhoneNumber, residentialType } = guarantor;
  const formattedPhoneNumber =
    contactPhoneNumber && contactCountryCode
      ? `${contactCountryCode} ${contactPhoneNumber}`
      : '';
  const formattedResidentalType = residentialType
    ? capitalize(residentialType)
    : '';

  return (
    <FixedContent header={`Guarantor ${index}`}>
      <GridContent>
        {FEATURE_FLAGS.FEATURE_FLAG_NEW_GUARANTOR_FIELDS ? (
          <Fragment>
            <LabeledContent label="Name" content={guarantor.name} />
            <LabeledContent label="Email" content={guarantor.email} />
            <LabeledContent
              label="Phone number"
              content={formattedPhoneNumber}
            />
            <LabeledContent
              label="Residential type"
              content={formattedResidentalType}
            />

            <LabeledContent
              label="Agreed to guarantee account"
              content={guarantor.approvedString}
            />
            <LabeledContent
              label="Position"
              content={guarantor.formattedPosition}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <LabeledContent label="Name" content={guarantor.name} />
              <LabeledContent
                label="Agreed to guarantee account"
                content={guarantor.approvedString}
              />
            </div>
            <div>
              <LabeledContent label="Email" content={guarantor.email} />
              <LabeledContent
                label="Position"
                content={guarantor.formattedPosition}
              />
            </div>
          </Fragment>
        )}
      </GridContent>
    </FixedContent>
  );
}

export default function Guarantors(props) {
  const { application } = props;
  if (!application && !application.id) {
    return;
  }
  let guarantors = application.guarantors;
  if (isEmpty(guarantors)) {
    guarantors = Array.from(
      {
        length:
          application.legalType === 'trust'
            ? application.minimumGuaranteesTrust
            : application.minimumGuarantees,
      },
      () => new GuarantorModel()
    );
  }
  if (FEATURE_FLAGS.FEATURE_FLAG_NEW_GUARANTOR_FIELDS) {
    const { signatures = [] } = application;
    guarantors = getGuarantorFieldsFromSignature(guarantors, signatures);
  }

  return guarantors.map((guarantor, index) => (
    <Guarantor
      key={`guarantor-${index + 1}`}
      index={index + 1}
      guarantor={guarantor}
    />
  ));
}
