import Switch from 'modules/shared/components/widgets/interactive/Switch';
import React, { useState } from 'react';

import Panel from '../Panel';
import { Props } from '../Panel/model';
import { ChannelMapChart } from './ChannelMapChart';
import ExperienceHeatMap from './ExperienceHeatMap';

const HeatMap = (props: Props) => {
  const tabOptions = {
    channels: {
      component: <ChannelMapChart {...props} />,
    },
    experience: {
      component: <ExperienceHeatMap {...props} />,
    },
  };

  const [current, setCurrent] = useState('channels');

  const handleChange = (value: string) => {
    setCurrent(value);
  };

  return (
    <Panel
      {...props}
      tooltip={
        'The heat map shows your customer base on the map. Select ‘channels’ to see application volumes by Sales channels; select ‘experience’ to see the average Customer experience score by regions.'
      }
      action={
        <Switch
          leftLabel="channels"
          rightLabel="experience"
          leftOption="channels"
          rightOption="experience"
          current={current}
          handleChange={handleChange}
          cssOverrides={{
            marginRight: 0,
          }}
          buttonCssOverrides={{
            display: 'inline-block',
            width: '100px',
          }}
        />
      }
      error={false}
      loading={false}
      title="Heat map"
    >
      {tabOptions[current].component}
    </Panel>
  );
};

export default HeatMap;
