import React from 'react';
import { WatchtowerAlertSummaryWrapper } from '../styles';
import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import formatMoney from 'utils/formatMoney';

const WatchtowerAlertSummary = ({ summary, hasActiveAlerts }) => {
  return (
    <WatchtowerAlertSummaryWrapper>
      <NumApplicationsBlock
        color={hasActiveAlerts ? 'red' : 'grey'}
        title="External"
        value={summary.externalAlerts}
        isCompact
        isWide
        bordered
        static
      />
      <NumApplicationsBlock
        color={hasActiveAlerts ? 'yellow' : 'grey'}
        title="Internal"
        value={summary.internalAlerts}
        isCompact
        isWide
        bordered
        static
      />
      <NumApplicationsBlock
        color={hasActiveAlerts ? 'blue' : 'grey'}
        title="Aggregate exposure"
        value={`$${formatMoney(summary.aggregateExposure)}`}
        isCompact
        isWide
        bordered
        static
      />
    </WatchtowerAlertSummaryWrapper>
  );
};

export default WatchtowerAlertSummary;
