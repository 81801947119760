import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getDirectors } from './utils';
import styles from './summary-report-styles.css';
import { TDirector } from './types';
import { formatDate } from 'utils/dateFormatter';
import { SectionContentContainer } from '../styles';

const DirectorsContent = ({ directors }) => {
  const { Directors = [] } = directors || {};

  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_2}>Name</th>
            <th className={styles.th + ' ' + styles.col_3}>Address</th>
            <th className={styles.th + ' ' + styles.col_2}>Apointed</th>
            <th className={styles.th + ' ' + styles.col_1}>Resigned</th>
            <th className={styles.col_4}></th>
          </tr>
        </thead>
        <tbody>
          {Directors.length &&
            Directors.map((director: TDirector, index) => (
              <tr key={index} className={styles.row}>
                <td className={styles.td + ' ' + styles.col_2}>
                  {director.Name || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {director.Address || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {formatDate(director.DateAppointed, 'DD/MM/YY') || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {director.Resigned || '-'}
                </td>
                <td className={styles.col_4}></td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const Directors = ({ isAllExpanded, data }) => {
  const directors = getDirectors(data);
  const hasSectionData = checkHasSectionData(directors);

  return (
    <ReviewContainer
      subHeading="Directors"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DirectorsContent directors={directors} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default Directors;
