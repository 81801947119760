import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import { ALERTS_NAME_MAP } from 'modules/new-applications/components/application-actions/Watchtower/Alerts/constants';
import { watchtowerAlertTheme } from 'modules/shared/helpers/colorPalettes';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';
import { toBoolean } from 'utils';
import { formatLocalTime } from 'utils/dateFormatter';
import { capitalizeSentence, formatMoney } from 'utils/formatting';
import getAccountTypeName from 'utils/getAccountTypeName';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const STATUS_SORT_ORDER = [
  'Application',
  'Cards',
  'Direct debit',
  'Guarantor',
  'Signatory',
  'Reference checks',
  'Credit checks',
  'eSignatures',
  'IUF',
  'Approval',
  'PPSR status',
  'Loaded in system',
];

function formatStatusDate(date) {
  return isPresent(date) ? formatLocalTime(date) : '';
}

function sortStatuses(statuses) {
  return statuses.sort((a, b) => {
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }

    if (a.sortOrder < b.sortOrder) {
      return -1;
    }

    return 0;
  });
}

export default class ReportApplicationModel extends BaseModel {
  static async loadApplications({ accessToken, entityId, params }) {
    const reportApplicationsAPI = api(
      'report_applications',
      accessToken,
      entityId
    );

    try {
      const result = await reportApplicationsAPI.getApplications({ params });

      const reportApplications = get(result, 'data.data', []);
      const meta = get(result, 'data.meta', {});

      const applications = reportApplications.map((application) => {
        const app = new ReportApplicationModel(application);
        app.actingAsSupplier = toBoolean(params.acting_as_supplier);
        return app;
      });

      return { applications, meta };
    } catch (error) {
      console.error(error);
      return { applications: [], meta: {} };
    }
  }

  static async loadApplicationFilterOptions({ accessToken, entityId }) {
    const reportApplicationsAPI = api(
      'report_applications',
      accessToken,
      entityId
    );
    try {
      const result = await reportApplicationsAPI.getApplicationFilterOptions();
      const options = get(result, 'data', {});
      return options;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  static async exportApplications({ accessToken, entityId, params }) {
    const applicationsAPI = api('applications', accessToken, entityId);
    try {
      await applicationsAPI.exportApplications(null, null, {
        params,
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  get actingAsSupplier() {
    return this._actingAsSupplier;
  }

  set actingAsSupplier(value) {
    this._actingAsSupplier = value;
  }

  get name() {
    const name = this.actingAsSupplier
      ? this.applicationDisplayName
      : this.supplierName;
    return capitalizeSentence(name);
  }

  get applicationDisplayName() {
    if (this.isFloating) {
      return this.consumerContactEmail;
    }

    return this.consumerName;
  }

  // An application is floating if it is not assigned to a consumer_id yet
  get isFloating() {
    return isBlank(this.consumerId);
  }

  get startedOn() {
    const date_started = this.statuses[0].started_at;
    if (date_started) {
      return formatLocalTime(date_started);
    }
    return '-';
  }

  get isCompleted() {
    return this.isDeclined || this.isAccepted;
  }

  get completedOn() {
    const applicationStatus =
      this.statuses.find((status) => status.name === 'Application') || {};
    return applicationStatus.status === 'complete' &&
      applicationStatus.completed_on
      ? applicationStatus.completed_on
      : '-';
  }

  get formattedApprovedAt() {
    if (this.isBulkApplication) {
      const date = this.submissionStartedAt || this.createdAt;
      return date ? formatLocalTime(date) : '-';
    }
    return this.approvedAt ? formatLocalTime(this.approvedAt) : '-';
  }

  get isCashApplication() {
    return this.applicationType === 'cash';
  }

  get cashAccountTypeName() {
    return getAccountTypeName(this.applicationType);
  }

  get limit() {
    if (FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER) {
      if (this.isCashApplication) {
        return this.cashAccountTypeName;
      }
    } else {
      if ((parseFloat(this.requestedLimit) || 0) === 0) {
        return '-';
      }
    }

    let limit = this.requestedLimit;
    if (this.isAccepted) {
      limit = this.tradeAccountLimit;
    }

    return `$${formatMoney(parseInt(limit) || 0)}`;
  }

  get formattedRequestedLimit() {
    if (FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER) {
      if (this.isCashApplication) {
        return this.cashAccountTypeName;
      }
    } else {
      if ((parseFloat(this.requestedLimit) || 0) === 0) {
        return '-';
      }
    }

    return `$${formatMoney(parseInt(this.requestedLimit) || 0)}`;
  }

  get formattedAcceptedLimit() {
    if (FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER && this.isCashApplication) {
      return this.cashAccountTypeName;
    }

    if (this.isAccepted) {
      return `$${formatMoney(parseInt(this.tradeAccountLimit) || 0)}`;
    }

    return this.formattedRequestedLimit;
  }

  get branchName() {
    return this.actingAsSupplier ? this.supplierName : this.consumerName;
  }

  get initiator() {
    return this.initiatorName;
  }

  get branchOrOwner() {
    return isHeadquarter() ? this.branchName : this.supplierContactName;
  }

  get contactName() {
    if (this.actingAsSupplier) {
      return this.consumerContactName;
    }

    return this.supplierContactName;
  }

  get contactEmail() {
    if (this.actingAsSupplier) {
      this.consumerContactEmail;
    }

    return this.supplierContactEmail;
  }

  get statusesFormatted() {
    const statuses = this.statuses.map((status, index) => {
      status.started_on = formatStatusDate(status.started_at);
      status.completed_on = formatStatusDate(status.completed_at);

      if (index === 0) {
        status.other = this.contactName;
      }

      if (index === 1) {
        status.other = this.contactEmail;
      }

      status.sortOrder = STATUS_SORT_ORDER.indexOf(status.name);

      return status;
    });

    return sortStatuses(statuses);
  }

  get formattedAlertType() {
    return capitalize(this.alertType || '');
  }

  /**
   * Get the row background color based on watchtower alerts.
   * @returns {string|null} The row background color, or null if no specific color is needed.
   */
  getRowColor(watchtowerExclusions = []) {
    if (this.shouldIgnoreWatchtowerAlerts(watchtowerExclusions)) {
      // Watchtower alerts are not applicable. So show default color.
      return null;
    }

    const externalWatchtowerAlerts =
      this.attributes.external_watchtower_alerts || [];
    const internalWatchtowerAlerts =
      this.attributes.internal_watchtower_alerts || [];

    // Actioned external alert that is part of a specified list => RED
    const hasSpecialExternalAlert = externalWatchtowerAlerts.some(
      (alert) =>
        alert.status !== 'open' &&
        specialExternalAlerts.includes(alert.alert_name)
    );

    if (hasSpecialExternalAlert) {
      return watchtowerAlertTheme.external_alert;
    }

    // Array with both external and internal watchtower alerts.
    const watchtowerAlerts = [
      ...externalWatchtowerAlerts,
      ...internalWatchtowerAlerts,
    ];

    const unactionedAlerts = watchtowerAlerts.filter(
      (alert) => alert.status === 'open'
    );

    if (unactionedAlerts.length > 0) {
      // There are unactined alerts.
      // The row color will be based on the type of the latest alert.

      // Sort the unactioned alerts in descending order.
      const alertsInDescendingOrder = sortWatchtowerAlerts(unactionedAlerts);

      // Pick and return the color based on the type of the latest alert.
      return alertsInDescendingOrder[0].alert_type === 'internal'
        ? watchtowerAlertTheme.internal_alert
        : watchtowerAlertTheme.external_alert;
    }

    // There are no unactioned alerts.
    // Now let's see if there is any alert that demands red color.

    // Blocked internal alert => RED
    const hasBlockedWatchtowerAlert = internalWatchtowerAlerts.some(
      (alert) => alert.status === 'blocked'
    );

    if (hasBlockedWatchtowerAlert) {
      // We refer to the color correspoding to external alerts, since we need
      // to show that red color.
      return watchtowerAlertTheme.external_alert;
    }

    // No particular color needed
    return null;
  }

  getInternalWatchtowerAlertNames(watchtowerExclusions = []) {
    if (this.shouldIgnoreWatchtowerAlerts(watchtowerExclusions)) {
      // Watchtower alerts are not applicable. So internal alert names need not be shown.
      return null;
    }

    const internalWatchtowerAlerts =
      this.attributes.internal_watchtower_alerts || [];

    const unactionedAlerts = internalWatchtowerAlerts.filter(
      (alert) => alert.status === 'open'
    );

    if (unactionedAlerts.length > 0) {
      return getWatchtowerAlertNames(unactionedAlerts);
    }

    const blockedAlerts = internalWatchtowerAlerts.filter(
      (alert) => alert.status === 'blocked'
    );

    if (blockedAlerts.length > 0) {
      return 'Blocked';
    }

    return null;
  }

  getExternalWatchtowerAlertNames(watchtowerExclusions = []) {
    if (this.shouldIgnoreWatchtowerAlerts(watchtowerExclusions)) {
      // Watchtower alerts are not applicable. So external alert names need not be shown.
      return null;
    }

    return getWatchtowerAlertNames(
      this.attributes.external_watchtower_alerts || []
    );
  }

  belongsToWatchtowerGroup(watchtowerExclusions = []) {
    if (this.shouldIgnoreWatchtowerAlerts(watchtowerExclusions)) {
      // Watchtower alerts are not applicable
      return false;
    }

    const internalWatchtowerAlerts =
      this.attributes.internal_watchtower_alerts || [];

    return (
      internalWatchtowerAlerts.length > 0 &&
      this.attributes.watchtower_alerts_grouped
    );
  }

  get hasAccountMonitoring() {
    return (
      Boolean(this.attributes.review_date) ||
      Boolean(this.attributes.delinquency_period)
    );
  }

  shouldIgnoreWatchtowerAlerts(watchtowerExclusions) {
    if (!FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_EXCLUSIONS_FIX) {
      return false;
    }

    let isApplicationIncomplete = false;
    const applicationStatus = this.statuses.find(
      (status) => status.name === 'Application'
    );
    if (applicationStatus) {
      const isApplicationComplete = ['complete', 'review_request'].includes(
        applicationStatus.status
      );
      isApplicationIncomplete = !isApplicationComplete;
    }

    const isIncompleteExcluded = watchtowerExclusions.includes('not_submitted');
    const isArchivedExcluded = watchtowerExclusions.includes('archived');

    return (
      (isApplicationIncomplete && isIncompleteExcluded) ||
      (this.archived && isArchivedExcluded)
    );
  }

  get isBulkApplication() {
    return this.source === 'bulk';
  }
}

// These are watchtower alerts, if present in an application,
// should show red color for the row in UI, even when they are actioned.
const specialExternalAlerts = [
  'au_company_deregistered',
  'nz_company_deregistered',
  'au_company_liquidation',
  'nz_company_liquidation',
  'au_company_receivership',
  'nz_company_receivership',
  'au_company_voluntary_administration',
  'nz_company_voluntary_administration',
  'au_company_insolvency',
  'nz_company_insolvency',
];

const sortWatchtowerAlerts = (alerts) =>
  alerts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

/**
 * Transforms an array of watchtower alerts into a string containing their names.
 *
 * @param {Array} alerts - An array of watchtower alerts.
 * @returns {string} A string containing the names of watchtower alerts,
 * separated by commas.
 */
const getWatchtowerAlertNames = (alerts) => {
  if (!alerts || alerts.length === 0) {
    return null;
  }

  // Get the names of all alerts in the array
  const alertNames = alerts.map((alert) => alert.alert_name);

  // Extract the unique names
  const uniqueAlertNames = Array.from(new Set(alertNames));

  return uniqueAlertNames
    .map((alertName) => ALERTS_NAME_MAP[alertName] || alertName)
    .join(', ');
};
