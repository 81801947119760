import PropTypes from 'prop-types';
import React from 'react';

import { Wrapper } from './styles';

function Actions({
  cameraOnly,
  handleFileUpload,
  handleCameraUpload,
  disabled,
}) {
  return (
    <Wrapper>
      {!cameraOnly && (
        <button
          className="button is-primary is-rounded"
          type="button"
          onClick={handleFileUpload}
          tabIndex={0}
          disabled={disabled}
        >
          Browse
        </button>
      )}
      <button
        className="button is-primary is-rounded"
        type="button"
        onClick={handleCameraUpload}
        tabIndex={1}
        disabled={disabled}
      >
        Take photo
      </button>
    </Wrapper>
  );
}

Actions.propTypes = {
  open: PropTypes.func.isRequired,
  setCameraMode: PropTypes.func.isRequired,
};

export default Actions;
