/* eslint-disable max-lines */

import debounce from 'debounce';
import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';
import ReportApplicationModel from 'models/ReportApplicationModel';
import UserModel from 'models/UserModel';
import { loadAddons } from 'modules/addons/actions';
import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import TableListSkeleton from 'modules/shared/components/v2/Skeletons/TableListSkeleton';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import Switch from 'modules/shared/components/widgets/interactive/Switch';
import { ApplicationRow } from './ApplicationRow';
import { canUserReviewVirtualCreditFile } from 'modules/shared/helpers/currentUserHelper';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';

import { USER_UPDATE_PREFERENCE_SUCCESS } from 'modules/user/constants';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { formatMoney } from 'utils/formatting';

import styles from './css/Reporting.css';
import { FEATURE_FLAGS } from 'conf';
import { DashboardPageContainer } from 'modules/shared/components';
import { Empty } from 'modules/shared/components/v2/Empty/Empty';
import { Search } from './Search';
import { toBoolean } from 'utils';

const EmptyContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 96px;
`;

const DEFAULT_PER_PAGE = 25;
const DEBOUNCE_INTERVAL = 500;

function ConsumerSupplierSwitch(props) {
  if (!props.isVisible) {
    return null;
  }

  const [currentRole, setCurrentRole] = useState('supplier');
  const handleChange = (value) => {
    props.updateSearchParams({ acting_as_supplier: value === 'supplier' });
    setCurrentRole(value);
  };

  const isLeftOptionActive = true;
  const isRightOptionActive = true;

  return (
    <Switch
      leftOption="supplier"
      rightOption="consumer"
      leftLabel="My customers"
      rightLabel="My suppliers"
      current={currentRole}
      handleChange={handleChange}
      isLeftOptionActive={isLeftOptionActive}
      isRightOptionActive={isRightOptionActive}
    />
  );
}

// current_user.current_user_entity_link.attributes.role_types.includes("admin")
function StatisticsBlock(props) {
  const { activeBlock, meta, onClickBlock } = props;
  const blockSettings = [
    { color: 'blue', key: 'started', name: 'Started' },
    { color: 'grey', key: 'incomplete', name: 'Incomplete' },
    { color: 'light-blue', key: 'pending', name: 'Pending' },
    { color: 'blue', key: 'approved', name: 'Ready' },
    { color: 'medium-grey', key: 'declined', name: 'Declined' },
    { color: 'medium-grey', key: 'archive', name: 'Archive' },
  ];
  const onClick = (blockKey) => onClickBlock({ page: 1, status: blockKey });

  return (
    <div className={styles.statistics_block}>
      {blockSettings.map((block) => (
        <NumApplicationsBlock
          key={block.key}
          color={block.color}
          title={block.name}
          value={meta[block.key] || 0}
          active={activeBlock === block.key}
          isCompact={true}
          onClick={() => onClick(block.key)}
        />
      ))}
      <NumApplicationsBlock
        color={'blue'}
        title={'Limits'}
        value={`$${formatMoney(parseFloat(meta.limits || 0))}`}
        active={false}
        isCompact={true}
        onClick={() => null}
      />
    </div>
  );
}

function MobileStatisticsBlocks(props) {
  const { activeBlock, meta, onClickBlock } = props;
  const blockSettings = [
    { key: 'started', name: 'Started' },
    { key: 'incomplete', name: 'Incomplete' },
    { key: 'pending', name: 'Pending' },
    { key: 'approved', name: 'Approved' },
  ];

  const onClick = (blockKey) => onClickBlock({ page: 1, status: blockKey });
  return (
    <div className={styles.mobilie_statistics_blocks}>
      {blockSettings.map((block) => (
        <MobileStatisticsBlock
          key={block.key}
          name={block.name}
          value={meta[block.key] || 0}
          active={activeBlock === block.key}
          onClick={() => onClick(block.key)}
        />
      ))}
    </div>
  );
}

export function MobileStatisticsBlock(props) {
  const { name, value, active, onClick } = props;

  return (
    <div
      className={
        active
          ? styles.mobilie_statistics_block_active
          : styles.mobilie_statistics_block
      }
      onClick={onClick}
    >
      <div className={styles.mobilie_statistics_block_text}>
        <span>{name}</span>
        <br />
        <span>{value}</span>
      </div>
    </div>
  );
}

function ApplicationTableHeader(props) {
  return (
    <div
      className={`${styles.grid_table} ${styles.table_header} ${styles.mobile_invisible}`}
    >
      <div>{props.isSupplier ? 'Customer' : 'Supplier'}</div>
      <div>Status</div>
      <div>Limit</div>
      <div>Started</div>
      <div>Completed</div>
      <div>{isHeadquarter() ? 'Tier' : 'Owner'}</div>
    </div>
  );
}

function ApplicationTable(props) {
  const {
    applications,
    isSupplier,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchMoreApplicationsOnMobile,
    searchParams,
    currentUser,
  } = props;
  const [currentIndex, setCurrentIndex] = useState();

  const [isWatchtowerActive, setIsWatchtowerActive] = useState(false);
  const [watchtowerExclusions, setWatchtowerExclusions] = useState([]);

  useEffect(() => {
    if (FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER) {
      AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: 'watchtower_module',
        entityId: currentUser.currentEntity.id,
      }).then((response) => {
        const watchtowerConfig = response[0];
        setIsWatchtowerActive(watchtowerConfig && watchtowerConfig.active);
        setWatchtowerExclusions(
          get(
            watchtowerConfig,
            'attributes.history_version.data[0].attributes.config.internal_alerts.application_status_exclusions',
            []
          )
        );
      });
    }
  }, []);

  if (loading) {
    return <TableListSkeleton />;
  }
  const hintOnMobile = noMoreApplications
    ? 'No more records'
    : 'Click to get more';

  const showViewGroup =
    isHeadquarter() &&
    isWatchtowerActive &&
    FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;

  return (
    <div className={styles.table_content}>
      <ApplicationTableHeader meta={meta} isSupplier={isSupplier} />
      {meta.total === 0 ? (
        <EmptyContainer>
          <Empty />
        </EmptyContainer>
      ) : (
        applications.map((application, index) => (
          <ApplicationRow
            application={application}
            current={currentIndex === index}
            index={index}
            isSupplier={isSupplier}
            key={`application-${index}`}
            searchParams={searchParams}
            setCurrentIndex={setCurrentIndex}
            showViewGroup={showViewGroup}
            watchtowerExclusions={watchtowerExclusions}
            isWatchtowerActive={isWatchtowerActive}
          />
        ))
      )}
      <div
        className={`${styles.mobile_visible} ${styles.bottom_loader}`}
        onClick={
          !loadingMore && !noMoreApplications && onFetchMoreApplicationsOnMobile
        }
      >
        {loadingMore ? (
          <TableListSkeleton css_class="loader_relative" />
        ) : (
          <span>{hintOnMobile}</span>
        )}
      </div>
    </div>
  );
}

function useApplicationsState(props) {
  const { currentUser, location } = props;
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreApplications, setNoMoreApplications] = useState(false);
  const [pageOfMobile, setPageOfMobile] = useState(1);
  const [applications, setApplications] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [meta, setMeta] = useState({});
  const entityId = get(currentUser, 'currentEntity.id');
  const [searchParams, setSearchParams] = useState({
    ...{
      acting_as_supplier: currentUser.currentEntity.supplier || false,
      page: 1,
      per: get(
        currentUser,
        'preferenceByCurrentEntity.reportApplicationPerPage',
        DEFAULT_PER_PAGE
      ),
      status: 'started',
    },
    ...location.query,
    ...(JSON.parse(localStorage.getItem(`searchParams-${entityId}`)) || {}),
  });

  const onFetchFilterOptions = () => {
    (async () => {
      const options = await ReportApplicationModel.loadApplicationFilterOptions(
        {
          accessToken: currentUser.accessToken,
          entityId,
        }
      );
      setFilterOptions(options);
    })();
  };

  const onFetchApplications = (params) => {
    (async () => {
      setLoading(true);
      const { applications, meta } =
        await ReportApplicationModel.loadApplications({
          accessToken: currentUser.accessToken,
          entityId,
          params: FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIPELINE
            ? { ...params, pipeline: 'sales' }
            : params,
        });
      browserHistory.push({
        pathname: '/dashboard/reporting',
        query: params,
        state: get(location, 'state'),
      });
      setNoMoreApplications(applications.length < DEFAULT_PER_PAGE);
      setApplications(applications);
      setMeta(meta);
      setLoading(false);
    })();
  };

  const onFetchMoreApplicationsOnMobile = () => {
    const existingApplications = applications;
    const page = pageOfMobile + 1;
    const params = { ...searchParams, page };

    (async () => {
      setLoadingMore(true);
      const { applications, meta } =
        await ReportApplicationModel.loadApplications({
          accessToken: currentUser.accessToken,
          entityId,
          params,
        });
      setNoMoreApplications(applications.length < DEFAULT_PER_PAGE);
      setApplications(existingApplications.concat(applications));
      setPageOfMobile(page);
      setMeta(meta);
      setLoadingMore(false);
    })();
  };

  const exportApplications = (params) => {
    (async () => {
      await ReportApplicationModel.exportApplications({
        accessToken: currentUser.accessToken,
        entityId,
        params,
      });
    })();
  };

  const resetMobilePage = () => {
    setPageOfMobile(1);
    setNoMoreApplications(false);
  };

  return {
    applications,
    exportApplications,
    filterOptions,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchApplications,
    onFetchFilterOptions,
    onFetchMoreApplicationsOnMobile,
    resetMobilePage,
    searchParams,
    setSearchParams,
  };
}

function Reporting(props) {
  const { currentUser, dispatch } = props;
  const {
    applications,
    exportApplications,
    filterOptions,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchApplications,
    onFetchFilterOptions,
    onFetchMoreApplicationsOnMobile,
    resetMobilePage,
    searchParams,
    setSearchParams,
  } = useApplicationsState(props);

  const fetchApplication = useRef(
    debounce(onFetchApplications, DEBOUNCE_INTERVAL)
  ).current;

  useEffect(() => {
    onFetchFilterOptions();
    dispatch(loadAddons('alert_module'));
  }, []);

  useEffect(() => {
    fetchApplication(searchParams);
    resetMobilePage();
  }, [searchParams]);

  const canReviewVirtualCreditFile =
    canUserReviewVirtualCreditFile(currentUser);

  const onClickPage = (page) => {
    updateSearchParams({ page });
  };

  const onClickPerPage = (per) => {
    updateSearchParams({ page: 1, per });
    currentUser.updatePreferences({ reportApplicationPerPage: per });
    dispatch({
      payload: currentUser.attributes.preference,
      type: USER_UPDATE_PREFERENCE_SUCCESS,
    });
  };

  const updateSearchParams = (params) => {
    const updatedParams = { ...searchParams, ...params };
    const newSearchParams = {};
    Object.keys(updatedParams).forEach((key) => {
      if (![null, '', 'All'].includes(updatedParams[key])) {
        newSearchParams[key] = updatedParams[key];
      }
    });
    if (!newSearchParams.start_date) {
      delete newSearchParams.start_date_from;
      delete newSearchParams.start_date_to;
    }
    if (!newSearchParams.review_date) {
      delete newSearchParams.review_date_from;
      delete newSearchParams.review_date_to;
    }
    if (newSearchParams.initiator_hq) {
      // As per https://1centre.atlassian.net/browse/BT-16213 "Initiated by"
      // filter is deprecated. If any user has activated that filter prior to
      // this change was released, the filter will stay active, since we save the
      // Sales pipeline filter params to local storage. So we need to clear the
      // filter, if present.
      delete newSearchParams.initiator_hq;
    }
    setSearchParams(newSearchParams);
    localStorage.setItem(
      `searchParams-${get(currentUser, 'currentEntity.id')}`,
      JSON.stringify(newSearchParams)
    );
  };

  let headerTitle = 'Applications';
  if (searchParams.acting_as_supplier) {
    headerTitle = 'Trade sales pipeline';
  }

  const canShowConsumerSupplierSwitch = () => {
    const roles = currentUser.currentUserEntityLink.roleTypes;
    const result = currentUser.currentEntity.supplier
      ? roles.includes('admin') ||
        ((roles.includes('approver') || roles.includes('standard')) &&
          roles.includes('consumer_manager'))
      : false;
    return result;
  };

  const [isCallCentreActive, setIsCallCentreActive] = useState(false);
  useEffect(() => {
    (async () => {
      const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: ['call_centre'],
        entityId: currentUser.currentEntity.id,
      });
      const addonConfig = addonConfigs[0];
      setIsCallCentreActive(addonConfig && addonConfig.active);
    })();
  }, []);

  return (
    <DashboardPageContainer
      pageTitle="Sales pipeline"
      headerComponents={
        <div className={styles.sales_header}>
          <div className={styles.switch}>
            <ConsumerSupplierSwitch
              isVisible={canShowConsumerSupplierSwitch()}
              updateSearchParams={updateSearchParams}
            />
          </div>
          <StatisticsBlock
            meta={meta}
            activeBlock={searchParams.status}
            onClickBlock={updateSearchParams}
          />
          <Search
            searchParams={searchParams}
            updateSearchParams={updateSearchParams}
            dynamicFilterOptions={filterOptions}
            exportApplications={exportApplications}
            isVisible={searchParams.acting_as_supplier}
            isCallCentreActive={isCallCentreActive}
            currentUser={currentUser}
            isDownloadDisabled={!applications || !!!applications.length}
          />
        </div>
      }
      contentComponents={
        <div className={styles.table_container}>
          <ApplicationTable
            applications={applications}
            isSupplier={toBoolean(searchParams.acting_as_supplier)}
            loading={loading}
            loadingMore={loadingMore}
            meta={meta}
            noMoreApplications={noMoreApplications}
            onFetchMoreApplicationsOnMobile={onFetchMoreApplicationsOnMobile}
            searchParams={searchParams}
            canReviewVirtualCreditFile={canReviewVirtualCreditFile}
            currentUser={currentUser}
          />
          {meta.total > 0 && (
            <div className={styles.mobile_invisible}>
              <Pagination
                dataLength={meta.total}
                currentPage={searchParams.page}
                todosPerPage={searchParams.per}
                resourceName="Applications"
                optionViewPage={[
                  { label: '25', value: DEFAULT_PER_PAGE },
                  { label: '50', value: 50 },
                ]}
                handleClick={onClickPage}
                handleClickTodosPerPage={onClickPerPage}
              />
            </div>
          )}

          <div className={styles.footer_container}>
            <MobileStatisticsBlocks
              meta={meta}
              activeBlock={searchParams.status}
              onClickBlock={updateSearchParams}
            />
          </div>
        </div>
      }
    />
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(Reporting);
