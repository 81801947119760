import moment from 'moment';
import {
  IAdditionalInfo,
  IHeader,
  ISummary,
  ICompanyInfo,
  IAccountPaymentInfo,
  IPPSRFinanceStatementSummary,
  ICreditActivitySummary,
  IPPSRFinanceStatementDetails,
  ICreditEnquiryDetail,
  IDefaults,
  IJudgements,
  IDocuments,
  IDirectorAffilitiations,
  IShareholderAffilitiations,
  ICurrentShareholders,
  IDirectorReport,
  IDirectors,
  TNameValuePair,
  TDirector,
  TShareholder,
  TDirectorAffiliate,
  TDirectorConsumerAddress,
  TDirectorConsumerKnownName,
  TDirectorReport,
} from './types';
import { capitalize } from 'utils/formatting';

export const getHeaderData = (data: any): IHeader => {
  const { Datasets, EnquiryNumber, RequestDate } = data || {};
  const { RegisteredName } = Datasets.CompanyInformation || {};

  return {
    RegisteredName,
    EnquiryNumber,
    RequestDate,
  } as IHeader;
};

const getItemTypeFromArray = (
  itemTypesArray: any[] = [],
  itemTypeProperty: string,
  itemType: string
) => {
  return (
    (itemTypesArray.find((item) => item[itemTypeProperty] === itemType) || {})
      .NameValuePairs || []
  );
};

export const getSummaryData = (data: any): ISummary => {
  const { ScoreCard, ExtraDataItems, Summaries } = data.Datasets || {};
  const { Score, Messages } = ScoreCard || {};

  const SummaryLegend = getItemTypeFromArray(
    ExtraDataItems,
    'ExtraDataItemType',
    'CentrixFullCommGen1ScoreCardBands'
  );

  const SummaryInformation = getItemTypeFromArray(
    Summaries,
    'SummaryItemType',
    'Report Summary'
  );

  return { Score, Messages, SummaryLegend, SummaryInformation } as ISummary;
};

export const getValueFromNameValueArray = (
  nameValueArray: TNameValuePair[] = [],
  valueName: string
) => {
  const nameValue: TNameValuePair =
    nameValueArray.find((item) => item.Name === valueName) ||
    ({} as TNameValuePair);

  return nameValue.Value;
};

const getValueFromItemTypeArray = (
  itemTypesArray: any[],
  itemTypeProperty: string,
  itemType: string,
  valueName: string
) => {
  const ItemTypeArray = getItemTypeFromArray(
    itemTypesArray,
    itemTypeProperty,
    itemType
  );

  return getValueFromNameValueArray(ItemTypeArray, valueName);
};

export const getAdditionalInfo = (data: any): IAdditionalInfo => {
  const { CompanyInformation, Summaries } = data.Datasets || {};
  const {
    NZBN,
    CompanyNumber,
    RegistrationStatus,
    RegisteredDate,
    DeregistrationDate,
    CompanyType,
    ConstitutionFiled: ConstitutionFiledBoolean,
    LastFilingDate = null,
    FinancialYearEnd = null,
    NZSXCode = null,
    IndustryCode = null,
    IndustryDescription = null,
    SharesIssued,
  } = CompanyInformation || {};
  const YearSinceFirstRegistered = moment().diff(
    moment(RegisteredDate).year().toString(),
    'years',
    false
  );
  const Directors = getValueFromItemTypeArray(
    Summaries,
    'SummaryItemType',
    'Report Summary',
    'Directors'
  );
  const Shareholders = getValueFromItemTypeArray(
    Summaries,
    'SummaryItemType',
    'Report Summary',
    'Shareholders'
  );

  return {
    NZBN,
    CompanyNumber,
    RegistrationStatus,
    RegisteredDate,
    DeregistrationDate,
    YearSinceFirstRegistered,
    CompanyType,
    Directors,
    ConstitutionFiled: `${ConstitutionFiledBoolean}`,
    FinancialYearEnd,
    LastFilingDate,
    NZSXCode,
    IndustryCode,
    IndustryDescription,
    SharesIssued,
    Shareholders,
  } as IAdditionalInfo;
};

export const getCompanyInfo = (data: any): ICompanyInfo => {
  const { CompanyInformation } = data.Datasets || {};
  const {
    RegisteredName,
    AddressForService,
    TradingName = null,
    PreviousRegisteredNames = null,
    PreviousTradingNames = null,
    UltimateHoldingCompany = null,
  } = CompanyInformation || {};

  const { AddressLine1, AddressLine2, AddressLine3 } = AddressForService;
  const AddressRegisteredOffice = `${AddressLine1} ${AddressLine2} ${AddressLine3}`;

  return {
    RegisteredName,
    TradingName,
    AddressRegisteredOffice,
    PreviousRegisteredNames,
    PreviousTradingNames,
    UltimateHoldingCompany,
  } as ICompanyInfo;
};

export const checkHasSectionData = (sectionData: any) => {
  return Object.values(sectionData || {}).some((value) =>
    typeof value === 'object' ? !!Object.keys(value || {}).length : !!value
  );
};

export const getAccountPaymentInfo = (data): IAccountPaymentInfo => {
  return {} as IAccountPaymentInfo;
};

export const getPpsrFinanceStatementDetail = (
  data: any
): IPPSRFinanceStatementDetails => {
  const { FinanceStatements } = data.Datasets || {};
  const { FinanceStatementDetails } = FinanceStatements || {};
  return { FinanceStatementDetails } as IPPSRFinanceStatementDetails;
};

export const getPpsrFinanceStatementSummary = (
  data: any
): IPPSRFinanceStatementSummary => {
  const { FinanceStatements } = data.Datasets || {};
  const {
    PPSRSearchDate,
    PPSRSearchId,
    FinanceStatementSummaries,
    TotalNumberOfFinancialStatements,
  } = FinanceStatements || {};

  const FinanceStatementSummary = getItemTypeFromArray(
    FinanceStatementSummaries,
    'SummaryItemType',
    'FinanceStatementsRegisteredByMonthPeriod'
  );

  const CollateralStatementSummary = getItemTypeFromArray(
    FinanceStatementSummaries,
    'SummaryItemType',
    'CollateralStatementsRegisteredByMonthPeriod'
  );

  return {
    PPSRSearchDate,
    PPSRSearchId,
    FinanceStatementSummary,
    CollateralStatementSummary,
    TotalNumberOfFinancialStatements,
  } as IPPSRFinanceStatementSummary;
};

export const getCreditActivitySummary = (data: any): ICreditActivitySummary => {
  const { Summaries } = data.Datasets || {};

  const Enquiries = getItemTypeFromArray(
    Summaries,
    'SummaryItemType',
    'EnquiriesSummaryByMonthPeriod'
  );

  const Judgements = getItemTypeFromArray(
    Summaries,
    'SummaryItemType',
    'JudgmentsSummaryByMonthPeriod'
  );

  const Defaults = getItemTypeFromArray(
    Summaries,
    'SummaryItemType',
    'DefaultsSummaryByMonthPeriod'
  );

  return { Enquiries, Judgements, Defaults } as ICreditActivitySummary;
};

export const getCreditEnquiryDetail = (data: any): ICreditEnquiryDetail => {
  const { PreviousEnquiries } = data.Datasets || {};

  return { PreviousEnquiries } as ICreditEnquiryDetail;
};

export const getDefaults = (data: any): IDefaults => {
  const { Defaults } = data.Datasets || {};
  return { Defaults } as IDefaults;
};

export const getJudgements = (data: any): IJudgements => {
  const { Judgements } = data.Datasets || {};
  return { Judgements } as IJudgements;
};

export const getDirectorReportData = (data: any): IDirectorReport => {
  const { DirectorsConsumerDetails: RawDirectorDetails = [] } =
    data.Datasets || {};

  const DirectorsConsumerDetails: TDirectorReport[] = RawDirectorDetails.map(
    (CurrentDirectorDetails) => {
      const {
        KnownNames: KnownNamesRaw = [],
        Addresses: AddressesRaw = [],
        Judgements,
        Insolvencies,
        Defaults,
        Surname,
        FirstName,
        MiddleName,
      } = CurrentDirectorDetails || {};
      const Name = `${Surname}, ${
        MiddleName ? `${MiddleName} ` : ''
      }${FirstName}`;

      const KnownNames: TDirectorConsumerKnownName[] = KnownNamesRaw.map(
        (nameData) => {
          const { Surname, MiddleName, FirstName, DateOfBirth } =
            nameData || {};
          return {
            Name: `${Surname}, ${
              MiddleName ? `${MiddleName} ` : ''
            }${FirstName}`,
            DateOfBirth,
          };
        }
      );

      const LastKnownAddressRaw = AddressesRaw.find(
        (address) => address.LastKnown
      );
      const LastKnownAddress: TDirectorConsumerAddress = {
        Address: `${LastKnownAddressRaw.AddressLine1} ${LastKnownAddressRaw.AddressLine2} ${LastKnownAddressRaw.Suburb} ${LastKnownAddressRaw.City}`,
        DateFirstLoaded: LastKnownAddressRaw.DateFirstLoaded,
      };

      const Addresses: TDirectorConsumerAddress[] = AddressesRaw.filter(
        (address) => !address.LastKnown
      ).map((address) => {
        return {
          Address: `${address.AddressLine1} ${address.AddressLine2} ${address.Suburb} ${address.City}`,
          DateFirstLoaded: address.DateFirstLoaded,
        };
      });

      const DirectorsConsumerDetails: TDirectorReport = {
        ...CurrentDirectorDetails,
        KnownNames,
        LastKnownAddress,
        Addresses,
        Judgments: Judgements ? `${Judgements.length}` : '0',
        Insolvencies: Insolvencies ? `${Insolvencies.length}` : '0',
        Defaults: Defaults ? `${Defaults.length}` : '0',
        Name,
      };
      return DirectorsConsumerDetails;
    }
  );

  return { DirectorsConsumerDetails } as IDirectorReport;
};

export const getDirectors = (data: any): IDirectors => {
  const { Directors: DirectorsRaw = [] } = data.Datasets || {};

  const Directors: TDirector[] = DirectorsRaw.map((director): TDirector => {
    const {
      Surname,
      FirstNames,
      Address1,
      Address2,
      Address3,
      DateAppointed,
      Resigned,
    } = director || {};

    return {
      Surname,
      FirstNames,
      Name: `${Surname}, ${FirstNames}`,
      Address: `${Address1 || ''} ${Address2 || ''} ${Address3 || ''}`,
      DateAppointed,
      Resigned,
    };
  });

  return { Directors } as IDirectors;
};

export const getDirectorAffiliations = (data: any): IDirectorAffilitiations => {
  const { Directors } = data.Datasets || {};
  const DirectorAffiliations: TDirectorAffiliate[] = Directors.reduce(
    (
      currentDirectorAffiliations,
      currentDirector: any
    ): TDirectorAffiliate[] => {
      const { Surname, FirstNames, CompanyAffiliations = [] } = currentDirector;

      CompanyAffiliations.forEach((affiliate) => {
        const { RegisteredOfficeAddress } = affiliate;
        const { AddressLine1, AddressLine2, AddressLine3 } =
          RegisteredOfficeAddress;
        currentDirectorAffiliations.push({
          Name: `${Surname}, ${FirstNames}`,
          Address: `${AddressLine1 || ''} ${AddressLine2 || ''} ${
            AddressLine3 || ''
          }`,
          ...affiliate,
        });
      });

      return currentDirectorAffiliations;
    },
    []
  );

  return { DirectorAffiliations } as IDirectorAffilitiations;
};

function calculatePercentage(value, total) {
  let percentage = (value / total) * 100;
  percentage = Math.round(percentage * 100) / 100;
  return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2);
}

export const getCurrentShareholders = (data: any): ICurrentShareholders => {
  const { Shareholders: ShareholdersRaw = [], CompanyInformation } =
    data.Datasets || {};

  const {
    SharesIssued: TotalNumberOfShares,
    ExtensiveShareholding: ExtensiveShareholdingRaw,
  } = CompanyInformation || {};

  const Shareholders: TShareholder[] = ShareholdersRaw.map(
    (shareholder): TShareholder => {
      const { Name, Address1, Address2, Address3, NumberOfShares } =
        shareholder || {};

      return {
        Name,
        Address: `${Address1 || ''} ${Address2 || ''} ${Address3 || ''}`,
        NumberOfShares,
        Percentage: `${calculatePercentage(
          NumberOfShares,
          TotalNumberOfShares
        )}%`,
      };
    }
  );

  return {
    Shareholders,
    TotalNumberOfShares,
    ExtensiveShareholding: capitalize(`${ExtensiveShareholdingRaw}`),
  } as ICurrentShareholders;
};

export const getShareholderAffiliations = (
  data: any
): IShareholderAffilitiations => {
  const { Shareholders = [] } = data.Datasets || {};

  const ShareholdersAffiliations = Shareholders.reduce(
    (currentShareholdersAffiliations, shareholder) => {
      const { ShareholderAffiliations = [] } = shareholder;
      ShareholderAffiliations.forEach((affiliate) =>
        currentShareholdersAffiliations.push(affiliate)
      );

      return currentShareholdersAffiliations;
    },
    []
  );

  return { ShareholdersAffiliations } as IShareholderAffilitiations;
};

export const getDocuments = (data: any): IDocuments => {
  const { Documents } = data.Datasets || {};

  return { Documents } as IDocuments;
};
