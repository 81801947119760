import React, { Fragment } from 'react';

import styles from './summary-report-styles.css';

import { getSummaryData } from './utils';
import {
  SummaryContentContainer,
  SummaryInformationTitle,
  SummaryMessageContainer,
  SummaryTableContainer,
} from '../styles';
import { ISummary } from './types';

const ReportSummary = ({ data }) => {
  const summaryData: ISummary = getSummaryData(data);
  if (!summaryData) return null;
  const { Score, Messages, SummaryLegend, SummaryInformation } = summaryData;
  if (!Score) return null;

  let score_masterscale = parseInt(Score),
    maxValue = 1000,
    minValue = 0,
    valuePosition = '0';

  if (score_masterscale < minValue) score_masterscale = minValue;
  valuePosition =
    (
      ((score_masterscale - minValue) / (maxValue - minValue)) *
      100
    ).toString() + '%';

  return (
    <div className={styles.row}>
      <SummaryContentContainer className={styles.col_4}>
        <SummaryInformationTitle>Summary Information</SummaryInformationTitle>
        <SummaryTableContainer>
          <table className={styles.table}>
            <tbody>
              {SummaryInformation &&
                SummaryInformation.length &&
                SummaryInformation.map((legend, index) => (
                  <tr key={index} className={styles.row}>
                    <th className={styles.th + ' ' + styles.half_col}>
                      {legend.Name}
                    </th>
                    <td className={styles.td + ' ' + styles.half_col}>
                      {legend.Value || '0'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </SummaryTableContainer>
      </SummaryContentContainer>

      <SummaryContentContainer className={styles.col_8}>
        <SummaryInformationTitle>Credit Score</SummaryInformationTitle>
        <SummaryTableContainer>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Score Range
                </th>
                <th className={styles.th + ' ' + styles.half_col}>Risk Band</th>
              </tr>
              {SummaryLegend &&
                SummaryLegend.length &&
                SummaryLegend.map((legend) => (
                  <tr className={styles.row}>
                    <td className={styles.td + ' ' + styles.half_col}>
                      {legend.Name}
                    </td>
                    <td className={styles.td + ' ' + styles.half_col}>
                      {legend.Value}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </SummaryTableContainer>

        <div className={styles.overview + ' pt-6 mb-4'}>
          <div className={styles.overview_rating_full}>
            <div className={styles.liner}>
              <div className={styles.overview_rating_graph}>
                <div className="has-text-centered is-size-3">
                  <span className="has-text-weight-semibold">
                    {score_masterscale}
                  </span>
                  <span className="has-text-dark"> / {maxValue}</span>
                </div>

                <div
                  className={
                    styles.overview_rating_indicator +
                    ' is-size-2 has-text-warning has-text-weight-bold'
                  }
                  style={{ left: valuePosition }}
                />

                <div className={styles.overview_rating_graph_bg_reverse} />
                <span className={styles.overview_rating_graph_minus_20_label}>
                  <strong>HIGH RISK</strong>
                </span>
                <span className={styles.overview_rating_graph_120_label}>
                  <strong>LOW RISK</strong>
                </span>
              </div>
            </div>
          </div>
        </div>

        <SummaryMessageContainer>
          {Messages &&
            Messages.length &&
            Messages.map((message) => <p>{message}</p>)}
        </SummaryMessageContainer>
      </SummaryContentContainer>
    </div>
  );
};

export default ReportSummary;
