import React, { Fragment, useState } from 'react';
import {
  ReportBody,
  ReportContainer,
  H4Title,
  HeaderContainer,
  HeaderLogo,
} from './styles';
import Button from 'modules/shared/components/inputs/Button';
import Header from './sections/Header';
import ReportSummary from './sections/Summary';
import AdditionalDetails from './sections/AdditionalDetails';
import CompanyInformation from './sections/CompanyInformation';
import AccountPaymentInformation from './sections/AccountPaymentInformation';
import PpsrFinanceStatementSummary from './sections/PpsrFinanceStatementsSummary';
import PpsrFinanceStatementDetail from './sections/PpsrFinanceStatementDetail';
import CreditActivitySummary from './sections/CreditActivitySummary';
import CreditEnquiryDetail from './sections/CreditEnquiryDetail';
import Defaults from './sections/Defaults';
import Judgements from './sections/Judgements';
import DirectorReport from './sections/DirectorReport';
import Directors from './sections/Directors';
import DirectorAffiliations from './sections/DirectorAffiliations';
import CurrentShareholders from './sections/CurrentShareholders';
import ShareholderAffiliations from './sections/ShareholderAffiliations';
import Documents from './sections/Documents';
import ReportSection from '../../australia/equifax/components/shared/ReportSection';
import ReportPdf from '../../australia/equifax/components/shared/ReportPdf';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import logo from 'images/centrix-logo-v2.png';

const CentrixCommercialComponent = ({ data, pdf, signed_pdf }) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const ExpandAllButton = () => {
    const buttonLabel = isAllExpanded ? '- collapse all' : '+ expand all';

    return (
      <Button
        small
        text={buttonLabel}
        style={{
          fontSize: '10px',
          margin: '1em 0 1em 66px',
          maxWidth: '100px',
        }}
        handleClick={() => setIsAllExpanded(!isAllExpanded)}
      />
    );
  };

  const checkIsError = (statusMessage) => {
    if (Array.isArray(statusMessage)) {
      return !!statusMessage.find(
        (m) => m.message_text && m.message_type === 'Error'
      );
    }
    return statusMessage.message_type === 'Error';
  };

  const statusMessages = data.status_messages || {};
  const statusMessage = statusMessages.status_message || [];
  const isError = checkIsError(statusMessage);

  if (isError) {
    return (
      <ReviewContainer content_class="content_wide" css_class="block_noborder">
        <HeaderLogo src={logo} alt="Centrix" />
        <div>There is an issue with the credit check.</div>
        <p>
          Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
          or live chat with us.
        </p>
      </ReviewContainer>
    );
  }

  return (
    <ReportContainer id="centrix-commercial-report">
      <H4Title>Commercial Credit Report</H4Title>
      <Header data={data} />
      <ReportSummary data={data} />
      <ReportBody>
        <ExpandAllButton />
        <AdditionalDetails isAllExpanded={isAllExpanded} data={data} />
        <CompanyInformation isAllExpanded={isAllExpanded} data={data} />
        <AccountPaymentInformation isAllExpanded={isAllExpanded} data={data} />
        <PpsrFinanceStatementSummary
          isAllExpanded={isAllExpanded}
          data={data}
        />
        <PpsrFinanceStatementDetail isAllExpanded={isAllExpanded} data={data} />
        <CreditActivitySummary isAllExpanded={isAllExpanded} data={data} />
        <CreditEnquiryDetail isAllExpanded={isAllExpanded} data={data} />
        <Defaults isAllExpanded={isAllExpanded} data={data} />
        <Judgements isAllExpanded={isAllExpanded} data={data} />
        <DirectorReport isAllExpanded={isAllExpanded} data={data} />
        <Directors isAllExpanded={isAllExpanded} data={data} />
        <DirectorAffiliations isAllExpanded={isAllExpanded} data={data} />
        <CurrentShareholders isAllExpanded={isAllExpanded} data={data} />
        <ShareholderAffiliations isAllExpanded={isAllExpanded} data={data} />
        <Documents isAllExpanded={isAllExpanded} data={data} />
        <ExpandAllButton />
        <ReportSection>
          <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
        </ReportSection>
      </ReportBody>
    </ReportContainer>
  );
};

export default CentrixCommercialComponent;
