// import { isMobile } from 'modules/shared/helpers/mobileDetect';
import styled, { css } from 'styled-components';
import { breakpointMax } from 'variables/responsive';
import { COLORS, FONT_COLORS, THEME_COLORS } from 'variables/theme';
import { FONT_SIZES, FONT_WEIGHTS } from 'variables/typography';

import { Wrapper as Message } from '../Message/styles';

type WrapperProps = {
  autoHeight?: boolean;
};

type LinerProps = {
  comingSoon?: boolean;
};

export const Beta = styled.span`
  bottom: 0.75rem;
  color: ${COLORS.mediumGrey};
  position: absolute;
  right: 1rem;
`;

export const Header = styled.div<{ $hasAction: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0 25px;

  ${(props) =>
    props.$hasAction &&
    css`
      position: relative;
      top: -4px;
    `}

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  h2 {
    color: ${FONT_COLORS.gray};
    display: inline-block;
    font-size: ${FONT_SIZES.title};
    font-weight: ${FONT_WEIGHTS.normal};
    line-height: 20px;
    margin-right: auto;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${Message} {
    margin: auto;
  }
`;

export const Liner = styled.div<LinerProps>`
  background-color: ${THEME_COLORS.panelBg};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 25rem;
  padding: 1.5rem;
  position: relative;

  @media (max-width: 767px) {
    min-height: 20rem;
  }

  ${(props) =>
    props.comingSoon &&
    css`
      ${InnerWrapper} {
        filter: blur(5px);
      }
    `}
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;

  ${breakpointMax('md')} {
    &:not(:last-child) {
      margin-bottom: -1.5rem;

      ${Liner} {
        border-bottom: 1px solid ${COLORS.darkGrey};
      }
    }
  }
`;
