import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import { AddNoteButton } from 'modules/new-applications/components/application-actions/Notes';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import {
  useBranchesState,
  useEntityUsersState,
  useSelectedBranchState,
  useSelectedUserState,
} from 'modules/new-applications/hooks/useBranchUserStates';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import Button from 'modules/shared/components/inputs/Button';
import LabeledInputContent from 'modules/shared/components/widgets/static/LabeledInputContent';
import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';
import { FEATURE_FLAGS } from 'conf';
import { Typography } from '@material-ui/core';
import { canUserCreateShadowVcf } from 'modules/shared/helpers/currentUserHelper';

const isShadowVcfEnabled = FEATURE_FLAGS.FEATURE_FLAG_SHADOW_VCF;

const getBranchOption = ({ branches, branchId }) =>
  branches.find((branch) => branch.value === branchId) || {};

const Container = styled.div`
  margin-top: 20px;
  width: max-content;
  @media screen and (min-width: 1279px) {
    width: 60%;
  }
`;

const InputLabel = ({ action, shadowVcf }) => {
  const mainLabel =
    action === 'reallocate'
      ? 'Reallocate application to'
      : 'Reassign application to';

  return (
    <Fragment>
      <p>{mainLabel}</p>
      {shadowVcf && `(with Shadow VCF)`}
    </Fragment>
  );
};

const formSchema = yup.object().shape({
  branchId: yup.string().required('Please select a tier'),
  userId: yup.string().required('Please select a user'),
});

const reassignFormSchema = yup.object().shape({
  userId: yup.string().required('Please select a user'),
});

const getReallocateHelperText = ({
  errorMessage,
  hasError,
  isDisabled,
  shadowVcf,
  shadowVcfBranch,
}) => {
  if (shadowVcfBranch) {
    return null;
  }

  if (isDisabled && !shadowVcf) {
    return 'Unable to reallocate once approval process has started';
  }

  if (hasError) {
    return errorMessage;
  }

  return ' ';
};

function ReallocateDropdown(props) {
  const {
    branches,
    errors,
    isDisabled,
    isVisible,
    onSelectBranch,
    selectedBranchOption,
    shadowVcf,
    shadowVcfBranch,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <LabeledInputContent
        label={
          shadowVcfBranch ? (
            'Leave Shadow VCF in'
          ) : (
            <InputLabel action="reallocate" shadowVcf={shadowVcf} />
          )
        }
      >
        <BorderedAutocomplete
          textFieldProps={{
            error: Boolean(errors.branchId),
            helperText: getReallocateHelperText({
              errorMessage: get(errors, 'branchId.message', ' '),
              hasError: Boolean(errors.branchId),
              isDisabled,
              shadowVcf,
              shadowVcfBranch,
            }),
            label: '',
            name: 'branchId',
          }}
          id="branch-autocomplete"
          disabled={shadowVcfBranch || isDisabled}
          options={branches}
          value={selectedBranchOption}
          onChange={onSelectBranch}
        />
      </LabeledInputContent>
    </div>
  );
}

function Form(props) {
  const {
    application,
    branches,
    currentUser,
    router,
    users,
    onFetchApplicationRecord,
    onFetchUsers,
    shadowVcf,
    isReallocateFieldVisible,
  } = props;

  const {
    selectedBranchId,
    selectedOption: selectedBranchOption,
    setSelectedBranchId,
  } = useSelectedBranchState({ branchId: application.supplierId, branches });

  const {
    selectedOption: selectedUserOption,
    selectedUserId,
    setSelectedUserId,
  } = useSelectedUserState({
    userId: shadowVcf ? '' : application.supplierContactId,
    users,
  });

  let validationSchema = reassignFormSchema;
  const defaultValues = { userId: selectedUserId };
  if (isPresent(branches)) {
    validationSchema = formSchema;
    defaultValues['branchId'] = selectedBranchId;
  }

  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      branchId: selectedBranchId,
      userId: selectedUserId,
    },
    mode: 'onBlur',
    validationSchema,
  });
  const { dirtyFields } = formState;

  const canCreateShadowVcf =
    application.canCreateShadowVcf && canUserCreateShadowVcf(currentUser);

  const isDisabled =
    isBlank(users) ||
    application.isNewRecord ||
    currentUser.isStandard ||
    (shadowVcf && !canCreateShadowVcf);
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onSuccessCallback = () => {
    mixpanel.track('Reallocate/reassign application', {
      'Application ID': application.id,
      'Entity ID': application.supplierId,
      'Target Entity ID': selectedBranchId,
      distinct_id: currentUser.id,
    });

    setIsLoading(false);
    reset();

    if (selectedBranchId === get(currentUser, 'currentEntity.id')) {
      onFetchApplicationRecord();
    } else {
      router.push('/dashboard/reporting');
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    let hasErrors = false;

    if (isDisabled) {
      return;
    }

    if (hasErrors) {
      return;
    }

    handleSubmit((data) => {
      setIsLoading(true);

      application.reallocateAndReassign({
        attributes: {
          supplier_contact_id: data.userId,
          supplier_id: data.branchId,
        },
        currentUser,
        onSuccessCallback,
      });
    })();
  };

  const onSelectBranch = (_, value) => {
    const selectedValue = (value || {}).value;

    clearError('branchId');

    setSelectedBranchId(selectedValue);
    setValue('branchId', selectedValue);

    onFetchUsers(selectedValue);
  };

  const onSelectUser = (_, value) => {
    const selectedValue = (value || {}).value;

    clearError('userId');

    setSelectedUserId(selectedValue);
    setValue('userId', selectedValue);
  };

  useEffect(() => {
    register({ name: 'branchId' });
    register({ name: 'userId' });
  }, [register]);

  const shadowVcfBranchOption = getBranchOption({
    branches,
    branchId: application.supplierId,
  });

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <ReallocateDropdown
          branches={branches}
          errors={errors}
          isDisabled={
            shadowVcf ? !canCreateShadowVcf : !application.canReallocate
          }
          isVisible={isReallocateFieldVisible}
          onSelectBranch={onSelectBranch}
          selectedBranchOption={selectedBranchOption}
          shadowVcf={shadowVcf}
        />
        <div>
          <LabeledInputContent
            label={<InputLabel action="reassign" shadowVcf={shadowVcf} />}
          >
            <BorderedAutocomplete
              textFieldProps={{
                error: Boolean(errors.userId),
                helperText: get(errors, 'userId.message', ' '),
                label: '',
                name: 'userId',
              }}
              id="users-autocomplete"
              name="users-autocomplete"
              options={users}
              value={selectedUserOption}
              disabled={isDisabled}
              onChange={onSelectUser}
            />
          </LabeledInputContent>
        </div>
        <ReallocateDropdown
          branches={branches}
          errors={errors}
          isVisible={shadowVcf}
          selectedBranchOption={shadowVcfBranchOption}
          shadowVcfBranch
        />
        <div>
          <Button
            disabled={isDisabled}
            loading={isLoading}
            disableOnLoading={true}
            text="Save"
            type="submit"
            style={{ marginRight: 60 }}
          />
          <AddNoteButton noteCategory="reallocate_reassign" {...props} />
        </div>
      </form>
    </Container>
  );
}

const ReallocateReassignForm = (props) => {
  const { application, branches, shadowVcf } = props;

  const { formattedUsers, onFetchUsers } = useEntityUsersState(props);

  useEffect(() => {
    onFetchUsers(application.supplierId);
  }, [application.supplierId]);

  return (
    <FixedContent
      header={shadowVcf ? 'Create shadow VCF' : 'Reallocate / Reassign'}
      toolTip={
        shadowVcf &&
        'Only users with permission can use this function. Contact your manager/admin if permission is required.'
      }
      withHelpIcon={shadowVcf}
    >
      {isShadowVcfEnabled && (
        <Typography>
          {shadowVcf
            ? 'Create shadow VCF and Reallocate the application to another tier. It will leave a non-actionable mirror of this application on the initiated tier, when reallocating it to the new tier you select. The function can only be used once on each pending application prior to the approval process.'
            : 'Reallocate the application to another tier or reassign it to another team member. The function can be used multiple times on each application prior to the approval process.'}
        </Typography>
      )}

      <Form
        branches={branches}
        users={formattedUsers}
        onFetchUsers={onFetchUsers}
        shadowVcf={shadowVcf}
        {...props}
      />
    </FixedContent>
  );
};

export default function ReallocateReassign(props) {
  const { application, currentUser } = props;
  const { reallocateReassignHistories } = application;

  const { formattedBranches, onFetchBranches } = useBranchesState(props);

  const isReallocateFieldVisible =
    isPresent(formattedBranches) &&
    application.isPersisted &&
    !currentUser.isStandard;

  useEffect(() => {
    onFetchBranches();
  }, []);

  const content = (
    <Fragment>
      <ReallocateReassignForm
        branches={formattedBranches}
        isReallocateFieldVisible={isReallocateFieldVisible}
        {...props}
      />
      {isShadowVcfEnabled && isReallocateFieldVisible && (
        <ReallocateReassignForm
          branches={formattedBranches}
          shadowVcf
          isReallocateFieldVisible={isReallocateFieldVisible}
          {...props}
        />
      )}
    </Fragment>
  );

  if (reallocateReassignHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={reallocateReassignHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
