import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { getAdditionalInfo, checkHasSectionData } from './utils';
import styles from './summary-report-styles.css';
import { formatDate } from 'utils/dateFormatter';
import { SectionContentContainer } from '../styles';

const AdditionalDetailsContent = ({ additionalInfo }) => {
  const {
    NZBN,
    CompanyNumber,
    RegistrationStatus,
    RegisteredDate,
    DeregistrationDate,
    YearSinceFirstRegistered,
    CompanyType,
    Directors,
    ConstitutionFiled,
    FinancialYearEnd,
    LastFilingDate,
    NZSXCode,
    IndustryCode,
    IndustryDescription,
    SharesIssued,
    Shareholders,
  } = additionalInfo;
  return (
    <SectionContentContainer>
      <div className={styles.row}>
        <div className={styles.col_4}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>NZBN</th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {NZBN || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Company Number
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {CompanyNumber || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Registration Status
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {RegistrationStatus || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Date First Registered
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {formatDate(RegisteredDate, 'DD/MM/YYYY') || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Year Since First Registered
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {YearSinceFirstRegistered || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Deregistration Date
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {formatDate(DeregistrationDate, 'DD/MM/YYYY') || '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.col_4}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Company Type
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {CompanyType || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>Directors</th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {Directors || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Constitution Filed
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {ConstitutionFiled || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Financial Year End
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {FinancialYearEnd || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Last Filing Date
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {formatDate(LastFilingDate, 'DD/MM/YYYY') || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>NZSX Code</th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {NZSXCode || '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.col_4}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Industry Code
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {IndustryCode || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Industry Description
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {IndustryDescription || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Shares Issued
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {SharesIssued || '-'}
                </td>
              </tr>
              <tr className={styles.row}>
                <th className={styles.th + ' ' + styles.half_col}>
                  Shareholders
                </th>
                <td className={styles.td + ' ' + styles.half_col}>
                  {Shareholders || '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SectionContentContainer>
  );
};

const AdditionalDetails = ({ isAllExpanded, data }) => {
  const additionalInfo = getAdditionalInfo(data);
  const hasSectionData = checkHasSectionData(additionalInfo);

  return (
    <ReviewContainer
      subHeading="Additional Details"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <AdditionalDetailsContent additionalInfo={additionalInfo} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default AdditionalDetails;
