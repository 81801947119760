import React, { useContext, useState } from 'react';
import { Table, TableBody } from '@material-ui/core';
import styled from 'styled-components';

import { TableHead } from './TableHead';
import { TableRow } from './TableRow';
import { CustomersContext } from 'modules/customers/CustomersContext';
import { Empty } from 'modules/shared/components/v2/Empty/Empty';

const StyledTable = styled(Table)`
  td,
  th {
    font-size: 1rem;
    padding-left: 0;
    padding-right: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .MuiTableCell-head {
    padding-top: 0;
  }
`;

const EmptyContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 96px;
`;

export const CustomersTable = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const { customers, isEmpty, showViewGroup, isWatchtowerActive } =
    useContext(CustomersContext);

  return (
    <StyledTable>
      <TableHead />
      {isEmpty ? (
        <EmptyContainer>
          <Empty />
        </EmptyContainer>
      ) : (
        <TableBody>
          {customers.map((customer, index) => (
            <TableRow
              {...customer}
              key={customer.id}
              showViewGroup={showViewGroup}
              expanded={index === openIndex}
              isWatchtowerActive={isWatchtowerActive}
              toggleExpand={() => {
                if (index === openIndex) {
                  setOpenIndex(null);
                } else {
                  setOpenIndex(index);
                }
              }}
            />
          ))}
        </TableBody>
      )}
    </StyledTable>
  );
};
