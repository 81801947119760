import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getCreditEnquiryDetail } from './utils';
import styles from './summary-report-styles.css';
import { formatDate } from 'utils/dateFormatter';
import { SectionContentContainer } from '../styles';

const CreditEnquiryDetailContent = ({ creditEnquiryDetail }) => {
  const { PreviousEnquiries = [] } = creditEnquiryDetail || {};
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_2}>Date</th>
            <th className={styles.th + ' ' + styles.col_3}>Company</th>
            <th className={styles.th + ' ' + styles.col_3}>Reason</th>
            <th className={styles.th + ' ' + styles.col_2}>Account Type</th>
            <th className={styles.th + ' ' + styles.col_2}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {PreviousEnquiries &&
            PreviousEnquiries.map((enquiry, index) => (
              <tr key={index} className={styles.row}>
                <td className={styles.td + ' ' + styles.col_2}>
                  {formatDate(enquiry.Date, 'DD/MM/YYYY') || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {enquiry.Enquirer || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {enquiry.EnquiryReason || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {enquiry.AccountType || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {enquiry.Amount || '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const CreditEnquiryDetail = ({ isAllExpanded, data }) => {
  const creditEnquiryDetail = getCreditEnquiryDetail(data);
  const hasSectionData = checkHasSectionData(creditEnquiryDetail);

  return (
    <ReviewContainer
      subHeading="Credit Enquiry Detail"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CreditEnquiryDetailContent creditEnquiryDetail={creditEnquiryDetail} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default CreditEnquiryDetail;
