import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getDocuments } from './utils';
import styles from './summary-report-styles.css';
import { IDocuments, TDocument } from './types';
import { formatDate } from 'utils/dateFormatter';
import { SectionContentContainer } from '../styles';

const DocumentsContent = ({ documents = [] }: { documents: TDocument[] }) => {
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_2}>Date</th>
            <th className={styles.th + ' ' + styles.col_4}>
              Document Description
            </th>
            <th className={styles.th + ' ' + styles.half_col}>Document Link</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document: TDocument, index) => (
            <tr key={index} className={styles.row}>
              <td className={styles.td + ' ' + styles.col_2}>
                {formatDate(document.DateRegistered, 'DD/MM/YY') || '-'}
              </td>
              <td className={styles.td + ' ' + styles.col_4}>
                {document.Description || '-'}
              </td>
              <td className={styles.td + ' ' + styles.half_col}>
                {document.DocumentUrl || '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const Documents = ({ isAllExpanded, data }) => {
  const documents: IDocuments = getDocuments(data);
  const hasSectionData = checkHasSectionData(documents);

  return (
    <ReviewContainer
      subHeading="Documents"
      content_class="content_wide"
      css_class="block_noborder"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
    >
      {hasSectionData ? (
        <DocumentsContent documents={documents.Documents} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default Documents;
