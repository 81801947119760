import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getCurrentShareholders } from './utils';
import styles from './summary-report-styles.css';
import {
  CurrentShareholdersTableWrapper,
  SectionContentContainer,
} from '../styles';
import { ICurrentShareholders } from './types';

const CurrentShareholdersContent = ({
  currentShareholders,
}: {
  currentShareholders: ICurrentShareholders;
}) => {
  const {
    Shareholders = [],
    ExtensiveShareholding,
    TotalNumberOfShares,
  } = currentShareholders || {};
  return (
    <SectionContentContainer>
      <CurrentShareholdersTableWrapper>
        <table className={styles.table}>
          <thead>
            <tr className={styles.row}>
              <th className={styles.th + ' ' + styles.col_3}>
                Total Number of Shares
              </th>
              <th className={styles.th + ' ' + styles.col_3}>
                Extensive Shareholding
              </th>
              <th className={styles.half_col}></th>
            </tr>
            <tr className={styles.row}>
              <td className={styles.td + ' ' + styles.col_3}>
                {TotalNumberOfShares || '-'}
              </td>
              <td className={styles.td + ' ' + styles.col_3}>
                {ExtensiveShareholding || '-'}
              </td>
              <td className={styles.half_col}></td>
            </tr>
          </thead>
        </table>
      </CurrentShareholdersTableWrapper>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Name</th>
            <th className={styles.th + ' ' + styles.col_3}>Address</th>
            <th className={styles.th + ' ' + styles.col_3}>Number of Shares</th>
            <th className={styles.th + ' ' + styles.col_3}>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {Shareholders &&
            Shareholders.map((shareholder, index) => (
              <tr key={index} className={styles.row}>
                <td className={styles.td + ' ' + styles.col_3}>
                  {shareholder.Name || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {shareholder.Address || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {shareholder.NumberOfShares || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {shareholder.Percentage || '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};
const CurrentShareholders = ({ isAllExpanded, data }) => {
  const currentShareholders = getCurrentShareholders(data);
  const hasSectionData = checkHasSectionData(currentShareholders);

  return (
    <ReviewContainer
      subHeading="Current Shareholders"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CurrentShareholdersContent currentShareholders={currentShareholders} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default CurrentShareholders;
