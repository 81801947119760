import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import {
  checkHasSectionData,
  getPpsrFinanceStatementSummary,
  getValueFromNameValueArray,
} from './utils';
import styles from './summary-report-styles.css';
import {
  BoldSpanText,
  PPSRSummaryTextContainer,
  SectionContentContainer,
} from '../styles';
import { formatDate } from 'utils/dateFormatter';

const PpsrFinanceStatementSummaryContent = ({
  ppsrFinanceStatementSummary,
}) => {
  const {
    PPSRSearchDate,
    PPSRSearchId,
    FinanceStatementSummary,
    CollateralStatementSummary,
    TotalNumberOfFinancialStatements,
  } = ppsrFinanceStatementSummary;

  return (
    <SectionContentContainer>
      <PPSRSummaryTextContainer>
        <BoldSpanText>
          <span>Search Date and Time:</span>{' '}
          {formatDate(PPSRSearchDate, 'DD/MM/YYYY H:mm:ss')}
        </BoldSpanText>
        <BoldSpanText>
          <span>Search ID:</span> {PPSRSearchId || '-'}
        </BoldSpanText>
        {TotalNumberOfFinancialStatements && (
          <p>{`There are ${TotalNumberOfFinancialStatements} PPSR statements registered for this organisation of which the most recent ${TotalNumberOfFinancialStatements} are reported here.`}</p>
        )}
      </PPSRSummaryTextContainer>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}></th>
            <th className={styles.th + ' ' + styles.half_col}>Months</th>
            <th className={styles.col_3}></th>
          </tr>
        </tbody>
      </table>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}></th>
            <th className={styles.th + ' ' + styles.col_1}>Total</th>
            <th className={styles.th + ' ' + styles.col_1}>0-6</th>
            <th className={styles.th + ' ' + styles.col_1}>7-12</th>
            <th className={styles.th + ' ' + styles.col_1}>13-18</th>
            <th className={styles.th + ' ' + styles.col_1}>19-24</th>
            <th className={styles.th + ' ' + styles.col_1}>25-60</th>
            <th className={styles.col_3}></th>
          </tr>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.col_3}>
              Finance Statements Registered
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(FinanceStatementSummary, 'Total') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(FinanceStatementSummary, '0-6') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(FinanceStatementSummary, '7-12') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(FinanceStatementSummary, '13-18') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(FinanceStatementSummary, '19-24') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(FinanceStatementSummary, '25-60') ||
                '-'}
            </td>
            <th className={styles.col_3}></th>
          </tr>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.col_3}>
              Collateral Statements Registered
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(
                CollateralStatementSummary,
                'Total'
              ) || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(CollateralStatementSummary, '0-6') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(CollateralStatementSummary, '7-12') ||
                '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(
                CollateralStatementSummary,
                '13-18'
              ) || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(
                CollateralStatementSummary,
                '19-24'
              ) || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(
                CollateralStatementSummary,
                '25-60'
              ) || '-'}
            </td>

            <th className={styles.col_3}></th>
          </tr>
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const PpsrFinanceStatementSummary = ({ isAllExpanded, data }) => {
  const ppsrFinanceStatementSummary = getPpsrFinanceStatementSummary(data);
  const hasSectionData = checkHasSectionData(ppsrFinanceStatementSummary);

  return (
    <ReviewContainer
      subHeading="PPSR Finance Statement Summary"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <PpsrFinanceStatementSummaryContent
          ppsrFinanceStatementSummary={ppsrFinanceStatementSummary}
        />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default PpsrFinanceStatementSummary;
