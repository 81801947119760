import { times } from 'lodash';
import { IOption } from 'types/generalType';
import { ICreditScore } from './type';

export const timeInBusinessOptions = [
  {
    label: 'less than one year',
    value: 'less_than_1_year',
  },
  {
    label: '1+ Years',
    value: 'years_1_plus',
  },
  {
    label: '2+ Years',
    value: 'years_2_plus',
  },
  {
    label: '3+ Years',
    value: 'years_3_plus',
  },
  {
    label: '5+ Years',
    value: 'years_5_plus',
  },
];

export const regionOptions = [
  { brief: 'NZ', label: 'NZ', value: 'NZ' },
  { brief: 'AU', label: 'AU', value: 'AU' },
];

export const getNumberOptions = (count: number): IOption[] =>
  times(count, (index) => {
    const value = ++index;
    return {
      label: `${value}`,
      value,
    };
  });

export const creditCheckScoreList: ICreditScore[] = [
  {
    id: '1',
    name: 'Equifax',
    region: 'NZ',
    description: 'NZ Standard Equifax Business report',
    minScore: 0,
    maxScore: 1200,
    type: 'business',
    slug: 'nz_equifax',
  },
  {
    id: '1',
    name: 'Equifax',
    region: 'NZ',
    description: 'NZ Equifax Score Plus',
    minScore: 0,
    maxScore: 1000,
    type: 'personal',
    slug: 'nz_equifax',
  },
  {
    id: '2',
    name: 'CreditWorks',
    region: 'NZ',
    description: 'NZ CreditWorks credit report',
    minScore: 0,
    maxScore: 1000,
    type: 'business',
    slug: 'nz_credit_works',
  },
  {
    id: '3',
    name: 'Centrix',
    region: 'NZ',
    description: 'NZ Centrix Comprehensive and Adverse Report',
    minScore: 0,
    maxScore: 1000,
    type: 'personal',
    slug: 'nz_centrix',
  },
  {
    id: '3',
    name: 'Centrix',
    region: 'NZ',
    description: 'Centrix Business Credit Report',
    minScore: 1,
    maxScore: 1000,
    type: 'business',
    slug: 'nz_centrix',
  },
  {
    id: '4',
    name: 'Equifax',
    region: 'AU',
    description: 'AU Equifax Scored Company/Business Enquiry',
    minScore: 0,
    maxScore: 1200,
    type: 'business',
    slug: 'au_equifax',
  },
  {
    id: '4',
    name: 'Equifax',
    region: 'AU',
    description: 'AU Equifax Commercial Apply',
    minScore: -200,
    maxScore: 1200,
    type: 'personal',
    slug: 'au_equifax',
  },
  {
    id: '4',
    name: 'Equifax',
    region: 'AU',
    description: 'AU Equifax Apply',
    minScore: -200,
    maxScore: 1200,
    type: 'individual',
    slug: 'au_equifax',
  },
  {
    id: '9',
    name: 'Illion',
    region: 'NZ',
    description: 'AU Illion Risk of Late Payment Report (DDS)',
    minScore: 101,
    maxScore: 799,
    type: 'business',
    slug: 'au_illion_payment_predictor',
  },
  {
    id: '10',
    name: 'Illion',
    region: 'AU',
    description: 'AU Illion Consumer Risk Score',
    minScore: -515,
    maxScore: 745,
    type: 'personal',
    slug: 'au_illion_consumer_risk_score',
  },
  {
    id: '42',
    name: 'Equifax',
    region: 'AU',
    description: 'AU Equifax Commercial Apply (Commercial + Consumer)',
    minScore: -200,
    maxScore: 1200,
    type: 'personal',
    slug: 'au_equifax_commercial_plus_consumer',
  },
];
