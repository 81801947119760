import { capitalizeSentence } from 'utils/formatting';

export const createRowEntry = (pipelineData) => {
  const { watchtower_alerts, consumer, consumer_contact_email, application } =
    pipelineData;

  const firstAlert = watchtower_alerts[0];
  const {
    alert_name,
    created_at,
    status,
    alert_type,
    updated_at,
    alert_source_data,
  } = firstAlert;

  const rowEntry = {
    alert_source_data,
    updated_at,
    alert_name,
    alert_type,
    created_at,
    customer_name: capitalizeSentence(
      consumer && (consumer.company_name || consumer.consumer_name)
        ? consumer.company_name || consumer.consumer_name
        : consumer_contact_email
    ),
    rawData: pipelineData,
    status,
    trade_account_limit: application.trade_account_limit || null,
    supplier_id: application.supplier_id,
    application_id: application.id,
    hasAccountMonitoring: !!(
      application.review_date || application.delinquency_period
    ),
  };

  return rowEntry;
};

const createBlockedAlertData = (alertsData) => {
  const tableData: any[] = [];

  alertsData.forEach((alertData) => {
    const { members } = alertData;

    if (members && members.length) {
      members.forEach((member) => {
        tableData.push(createRowEntry(member));
      });
    }
  });

  return tableData;
};

export const createAlertData = (alerts, activeBlock) => {
  if (!alerts.data) return [];

  const alertsData = alerts.data;
  if (activeBlock === 'blocked') {
    return createBlockedAlertData(alertsData);
  }

  return alertsData.map((alertData) => {
    const { members } = alertData;

    const tableRow = members.reduce((rowData, memberData, index) => {
      const rowEntry = createRowEntry(memberData);
      if (index === 0) {
        rowData = rowEntry;
      } else {
        if (!rowData.rowGroupData) {
          rowData.rowGroupData = [];
        }
        rowData.rowGroupData.push(rowEntry);
      }

      return rowData;
    }, {});

    return {
      ...tableRow,
    };
  });
};

export const defaultSearchParams = {
  source_page: 'watchtower',
  page: 1,
  per: 25,
};

export const createQuery = (block, currentQuery) => {
  switch (block) {
    case 'total':
      delete currentQuery['alert_type'];
      delete currentQuery['unactioned'];
      delete currentQuery['watchtower_alert_status'];

      return {
        ...currentQuery,
        ...defaultSearchParams,
      };

    case 'unactioned_external':
      delete currentQuery['watchtower_alert_status'];

      return {
        ...currentQuery,
        page: 1,
        bucket_query: 'bucket_unactioned_external',
      };

    case 'unactioned_internal':
      delete currentQuery['watchtower_alert_status'];

      return {
        ...currentQuery,
        page: 1,
        bucket_query: 'bucket_unactioned_internal',
      };

    case 'fraudulent':
    case 'blocked':
      delete currentQuery['alert_type'];
      delete currentQuery['unactioned'];

      return {
        ...currentQuery,
        page: 1,
        bucket_query: 'bucket_blocked',
      };

    case 'account_monitoring':
      delete currentQuery['alert_type'];
      delete currentQuery['unactioned'];

      return {
        ...currentQuery,
        page: 1,
        bucket_query: 'bucket_account_monitoring',
      };

    case 'undesirable':
      delete currentQuery['alert_type'];
      delete currentQuery['unactioned'];

      return {
        ...currentQuery,
        page: 1,
        bucket_query: 'bucket_undesirable',
      };

    default:
      return {};
  }
};

const filterData = (data, filter) => {
  return data
    .map((alertData) => {
      if (alertData.members) {
        return {
          members: alertData.members
            .map((alertMember) => ({
              ...alertMember,
              watchtower_alerts: alertMember.watchtower_alerts.filter((alert) =>
                filter(alert)
              ),
            }))
            .filter((alertMember) => alertMember.watchtower_alerts.length),
        };
      } else {
        return {
          ...alertData,
          watchtower_alerts: alertData.watchtower_alerts.filter((alert) =>
            filter(alert)
          ),
        };
      }
    })
    .filter((alertData) => {
      if (alertData.members) {
        return alertData.members.length;
      } else {
        alertData.watchtower_alerts.length;
      }
    });
};

export const filterFetchedAlert = (response, activeBlock) => {
  const { data = [], meta } = response;
  switch (activeBlock) {
    case 'unactioned_external': {
      const filterFunction = (alert) => {
        return alert.status === 'open' && alert.alert_type === 'external';
      };
      return {
        data: filterData(data, filterFunction),
        meta,
      };
    }

    case 'unactioned_internal': {
      const filterFunction = (alert) => {
        return alert.status === 'open' && alert.alert_type === 'internal';
      };
      return {
        data: filterData(data, filterFunction),
        meta,
      };
    }

    default:
      return { data, meta };
  }
};
