export function getEighteenYearsAgo() {
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(new Date().getFullYear() - 18);

  return eighteenYearsAgo;
}

export function get100YearsAgo() {
  return new Date(new Date().getFullYear() - 100, 0, 1);
}

export function getPastYearsAgo(number) {
  const validatedNumber = typeof number === 'number' ? number : 0;
  return new Date(new Date().getFullYear() - validatedNumber, 0, 1);
}
