import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import React from 'react';

import { StatisticBlocksWrapper } from '../styles';
import { FEATURE_FLAGS } from 'conf';

const StatisticsBlock = (props) => {
  const { activeBlock, summary, onClickBlock } = props;

  const onClick = (block) => onClickBlock({ active_block: block, page: 1 });

  return (
    <StatisticBlocksWrapper>
      <NumApplicationsBlock
        color="blue"
        title="Total alerted applications"
        value={summary.total_alerted_applications || 0}
        active={activeBlock === 'total'}
        isCompact
        isWide
        onClick={() => onClick('total')}
      />

      {FEATURE_FLAGS.FEATURE_FLAG_UPDATED_WATCHTOWER_VCF ? (
        <React.Fragment>
          <NumApplicationsBlock
            color="medium-grey"
            title={'Account monitoring'}
            value={summary.total_account_monitoring || 0}
            active={activeBlock === 'account_monitoring'}
            isCompact
            isWide
            onClick={() => onClick('account_monitoring')}
          />

          <NumApplicationsBlock
            color="yellow"
            title="Undesirable"
            value={summary.total_undesirable || 0}
            active={activeBlock === 'undesirable'}
            isCompact
            isWide
            onClick={() => onClick('undesirable')}
          />

          <NumApplicationsBlock
            color="red"
            title="Fraudulent"
            value={summary.total_blocked || 0}
            active={activeBlock === 'fraudulent'}
            isCompact
            isWide
            onClick={() => onClick('fraudulent')}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NumApplicationsBlock
            color="red"
            title="Unactioned external"
            value={summary.total_unactioned_external || 0}
            active={activeBlock === 'unactioned_external'}
            isCompact
            isWide
            onClick={() => onClick('unactioned_external')}
          />

          <NumApplicationsBlock
            color="yellow"
            title="Unactioned internal"
            value={summary.total_unactioned_internal || 0}
            active={activeBlock === 'unactioned_internal'}
            isCompact
            isWide
            onClick={() => onClick('unactioned_internal')}
          />

          <NumApplicationsBlock
            color="medium-grey"
            title={'Future task'}
            value={summary.total_account_monitoring || 0}
            active={activeBlock === 'account_monitoring'}
            isCompact
            isWide
            onClick={() => onClick('account_monitoring')}
          />
          <PopperTooltip
            title={
              'Any confirmed fraudulent accounts will be blocked and shared within the 1Centre community, to help create a safer trade environment.'
            }
          >
            <span>
              <NumApplicationsBlock
                color="black"
                title="Blocked"
                value={summary.total_blocked || 0}
                active={activeBlock === 'blocked'}
                isCompact
                isWide
                onClick={() => onClick('blocked')}
              />
            </span>
          </PopperTooltip>
        </React.Fragment>
      )}
    </StatisticBlocksWrapper>
  );
};

export default StatisticsBlock;
