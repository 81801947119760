import React from 'react';
import logo from 'images/centrix-logo-v2.png';
import {
  HeaderContainer,
  HeaderContents,
  HeaderLogo,
  HeaderLogoContainer,
  HeaderCompanyName,
} from '../styles';
import { getHeaderData } from './utils';
import { formatDate } from 'utils/dateFormatter';
import { IHeader } from './types';

const Header = ({ data }) => {
  const headerData: IHeader = getHeaderData(data);
  if (!headerData) return null;

  return (
    <HeaderContainer>
      <HeaderContents>
        <HeaderCompanyName>
          {headerData.RegisteredName || '-'}
        </HeaderCompanyName>
        <div>
          <span>Enquiry Number: </span>
          <span>{headerData.EnquiryNumber || '-'}</span>
        </div>
        <div>
          <span>Date Generated: </span>
          <span>{formatDate(headerData.RequestDate, 'DD/MM/YYYY') || '-'}</span>
        </div>
      </HeaderContents>
      <HeaderLogoContainer>
        <HeaderLogo src={logo} alt="Centrix" />
      </HeaderLogoContainer>
    </HeaderContainer>
  );
};

export default Header;
