import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getDirectorAffiliations } from './utils';
import styles from './summary-report-styles.css';
import { IDirectorAffilitiations, TDirectorAffiliate } from './types';
import { formatDate } from 'utils/dateFormatter';
import { SectionContentContainer } from '../styles';

const DirectorAffiliationsContent = ({
  DirectorAffiliations = [],
}: {
  DirectorAffiliations: TDirectorAffiliate[];
}) => {
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_2}>Name</th>
            <th className={styles.th + ' ' + styles.col_2}>Company</th>
            <th className={styles.th + ' ' + styles.col_3}>Address</th>
            <th className={styles.th + ' ' + styles.col_1}>Number</th>
            <th className={styles.th + ' ' + styles.col_1}>NZBN</th>
            <th className={styles.th + ' ' + styles.col_1}>Status</th>
            <th className={styles.th + ' ' + styles.col_1}>Appointed</th>
            <th className={styles.th + ' ' + styles.col_1}>Resigned</th>
          </tr>
        </thead>
        <tbody>
          {DirectorAffiliations.length &&
            DirectorAffiliations.map((affiliate: TDirectorAffiliate) => (
              <tr className={styles.row}>
                <td className={styles.td + ' ' + styles.col_2}>
                  {affiliate.Name || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {affiliate.CompanyName || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {affiliate.Address || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {affiliate.CompanyNumber || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {affiliate.NZBN || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {affiliate.CompanyStatusCodeDescription || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {formatDate(affiliate.DateAppointed, 'DD/MM/YYYY') || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {affiliate.Resigned || '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const DirectorAffiliations = ({ isAllExpanded, data }) => {
  const directorAffiliations: IDirectorAffilitiations =
    getDirectorAffiliations(data);
  const { DirectorAffiliations = [] } = directorAffiliations || {};

  const hasSectionData =
    checkHasSectionData(directorAffiliations) && DirectorAffiliations.length;

  return (
    <ReviewContainer
      subHeading="Director Affiliations"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DirectorAffiliationsContent
          DirectorAffiliations={DirectorAffiliations}
        />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default DirectorAffiliations;
