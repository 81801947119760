import React, { useCallback } from 'react';
import { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { debounce } from 'debounce';
// @ts-ignore
import { browserHistory } from 'react-router';

import ReportApplicationModel from 'models/ReportApplicationModel';
import { processError } from 'utils';
import { ApplicationsMeta } from 'types/application';
import { FEATURE_FLAGS } from 'conf';
import { useCustomersParams } from './useCustomersParams';
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
} from 'constants/pagination';
import useFilterState from 'modules/reporting/hooks/useFilterState';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';
import AddonConfigModel from 'models/AddonConfigModel';

const DEBOUNCE_DELAY = 1000; // in milliseconds

const isWatchtowerEnabled = FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;

type Customer = {
  id: string;
  customerName: string;
  approvedLimit: string;
  date: string;
  tier: string;
  isFromBulkUpload: boolean;
  rowColor?: string;
  internalWatchtowerAlertNames?: string;
  externalWatchtowerAlertNames?: string;
  callCentreActivity: boolean;
  applicationType: string;
  hasAccountMonitoring: boolean;
};

type Params = {
  page: number;
  per: number;
  key_word: string;
  limit_min?: number;
  limit_max?: number;
  source?: string;
  branch?: string;
  region?: string;
  application_type?: string;
  entity_type?: string;
  only_current_user: string;
  status: string;
  isMobile?: string;
};

type Meta = Pick<
  ApplicationsMeta,
  'active' | 'archive' | 'limits' | 'started' | 'total'
>;

type CustomersContext = {
  isLoading: boolean;
  isEmpty: boolean;
  meta: Meta;
  customers: Customer[];
  params: Params;
  toggleFilter: () => void;
  isDownloadDisabled: boolean;
  showViewGroup: boolean;
  isWatchtowerActive: boolean;
};

type CustomersContextProviderProps = {
  children: JSX.Element;
  entityId: string;
  isHeadquarter: boolean;
};

const defaultMeta = {
  active: 0,
  started: 0,
  archive: 0,
  limits: '0',
  total: 0,
};

const defaultContextValues = {
  isLoading: false,
  isEmpty: false,
  meta: defaultMeta,
  customers: [],
  params: {
    page: DEFAULT_PAGE_NUMBER,
    per: DEFAULT_ITEMS_PER_PAGE,
    key_word: '',
    source: 'All',
    branch: 'All',
    region: 'All',
    application_type: 'All',
    entity_type: 'All',
    only_current_user: 'false',
    status: 'all',
  },
  toggleFilter: () => {},
  isDownloadDisabled: true,
  showViewGroup: false,
  isWatchtowerActive: false,
};

export const CustomersContext =
  createContext<CustomersContext>(defaultContextValues);

export const CustomersContextProvider = connect((state) => ({
  entityId: state.current_user.current_entity.id,
}))((props: CustomersContextProviderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { children, entityId } = props;

  const params = useCustomersParams();

  const [isWatchtowerActive, setIsWatchtowerActive] = useState<boolean>(false);
  const [isCallCentreActive, setIsCallCentreActive] = useState<boolean>(false);
  useEffect(() => {
    AddonConfigModel.fetchAddonConfigByAddonType({
      addonType: ['call_centre', 'watchtower_module'],
      entityId: entityId,
      accessToken: undefined,
    }).then((response) => {
      const callCenterConfigArray =
        response.filter((addon) => addon.addonModuleName === 'call_centre') ||
        [];
      const watchtowerConfigArray =
        response.filter(
          (addon) => addon.addonModuleName === 'watchtower_module'
        ) || [];
      const watchtowerConfig =
        watchtowerConfigArray && watchtowerConfigArray[0];
      const callCenterConfig =
        callCenterConfigArray && callCenterConfigArray[0];
      setIsWatchtowerActive(watchtowerConfig && watchtowerConfig.active);
      setIsCallCentreActive(callCenterConfig && callCenterConfig.active);
    });
  }, []);

  const { filter, filterParams, onToggleIsOpen } = useFilterState(
    {
      start_date: 'All',
      approved_at: 'All',
      source: 'All',
      branch: 'All',
      sales_channel: 'All',
      region: 'All',
      application_type: 'All',
      entity_type: 'All',
      review_date: 'All',
      with_watchtower_alerts: 'All',
      ppsr_applies: 'All',
      ...params,
      source_page: 'customer',
    },
    { isCallCentreActive }
  );

  const [meta, setMeta] = useState<Meta>(defaultMeta);
  const [applications, setApplications] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [dynamicFilterOptions, setDynamicFilterOptions] = useState<{
    [key: string]: { value: string; label: string }[];
  }>({});

  const customersFilter = React.cloneElement(filter, {
    dynamicFilterOptions,
    filterType: 'customer',
  });

  const customers = applications.map((application) => {
    const { id } = application;
    const isFromBulkUpload = application.isBulkApplication;
    const tier =
      !isHeadquarter() && isFromBulkUpload
        ? 'Rapid Transfer'
        : application.branchOrOwner;

    return {
      id,
      customerName: application.name,
      approvedLimit: application.formattedAcceptedLimit,
      date: application.formattedApprovedAt,
      tier,
      expandable: isWatchtowerEnabled && application.belongsToWatchtowerGroup(),
      isFromBulkUpload,
      rowColor: application.getRowColor(),
      internalWatchtowerAlertNames:
        application.getInternalWatchtowerAlertNames(),
      externalWatchtowerAlertNames:
        application.getExternalWatchtowerAlertNames(),
      callCentreActivity: application.callCentreActivity,
      applicationType: application.applicationType,
      hasAccountMonitoring: application.hasAccountMonitoring,
      applicationVersion: application.versionNumber,
    };
  });

  const fetchApplications = async (params: Params) => {
    setLoading(true);
    try {
      const { applications, meta } =
        await ReportApplicationModel.loadApplications({
          accessToken: '',
          entityId,
          params: {
            acting_as_supplier: true,
            limit_type: 'approved_limits',
            pipeline: 'customer',
            ...params,
            isMobile: undefined, // This param is not to be sent to the API
          },
        });
      setMeta({
        active: meta.active || 0,
        started: meta.started || 0,
        archive: meta.archive || 0,
        limits: meta.limits || '0',
        total: meta.total || 0,
      });
      if (params.isMobile === 'true') {
        setApplications((currentApplications) => [
          ...currentApplications,
          ...applications,
        ]);
      } else {
        setApplications(applications);
      }
    } catch (error: any) {
      const { errorMessage } = processError(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onFetchApplications = useCallback(
    debounce(fetchApplications, DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    onFetchApplications(params);
  }, [params]);

  const getDynamicFilterOptions = async () => {
    try {
      const filterOptions =
        await ReportApplicationModel.loadApplicationFilterOptions({
          accessToken: '',
          entityId,
        });
      setDynamicFilterOptions(filterOptions);
    } catch (error) {}
  };

  useEffect(() => {
    getDynamicFilterOptions();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    // Grab the keys of the object returned by useFilterState hook
    const filterKeys = Object.keys(filterParams);

    // Iterate over the keys to set query params
    filterKeys.forEach((key) => {
      const value = filterParams[key];

      // If the value of the filter is selected as 'All',
      // or if it is null, we need not include it in the query params
      // that we send to API.
      if (value === 'All' || value === null || value === '') {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value);
      }
    });

    if (filterParams['region'] === 'All') {
      // If region is set to 'All', we need to remove the state filter too
      urlParams.delete('state');
    }

    if (filterParams['with_watchtower_alerts'] === 'grouped') {
      // The API query param 'watchtower_alerts_grouped' needs to be set when
      // Grouped is selected as Watchtower filter option
      urlParams.set('with_watchtower_alerts', 'true');
      urlParams.set('watchtower_alerts_grouped', 'true');
      urlParams.delete('irregular');
    } else if (filterParams['with_watchtower_alerts'] === 'irregular') {
      // The API query param 'irregular' needs to be set when
      // Irregular is selected as Watchtower filter option
      urlParams.set('with_watchtower_alerts', 'true');
      urlParams.set('irregular', 'true');
      urlParams.delete('watchtower_alerts_grouped');
    } else {
      urlParams.delete('watchtower_alerts_grouped');
      urlParams.delete('irregular');
    }

    // Since we are applying a filter, reset page number
    urlParams.set('page', String(DEFAULT_PAGE_NUMBER));

    if (params.per) {
      urlParams.set('per', String(params.per));
    }
    if (params.key_word) {
      urlParams.set('key_word', params.key_word);
    }

    // Navigate to the URL with the new query params.
    // The change in query params will trigger an API call.
    browserHistory.push(`/dashboard/customers?${urlParams}`);
  }, [filterParams]);

  const isEmpty = !isLoading && meta.total === 0;

  return (
    <CustomersContext.Provider
      value={{
        isLoading,
        isEmpty,
        meta,
        customers,
        params,
        toggleFilter: onToggleIsOpen,
        isDownloadDisabled: customers.length === 0,
        showViewGroup: isWatchtowerActive && isHeadquarter(),
        isWatchtowerActive,
      }}
    >
      {children}
      {customersFilter}
    </CustomersContext.Provider>
  );
});
