import styled from 'styled-components';
import { FORM_SPACING, SPACING } from 'variables/spacing';
import { BORDERS } from 'variables/theme';
import { FONT_SETS } from 'variables/typography';

export const Title = styled.h3`
  ${FONT_SETS.sectionHeader};
  margin-bottom: ${FORM_SPACING.titleMargin};
`;

interface SectionTitleProps {
  nfc_title_pTop?: string;
}

export const Section = styled.div<SectionTitleProps>`
  margin-top: 1rem;
  > *:not(:first-child) {
    ${Title} {
      border-top: ${BORDERS.generic};
      padding-top: ${(props) => props.nfc_title_pTop};
    }
  }
`;

Section.defaultProps = {
  nfc_title_pTop: SPACING.lg,
};
