import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import {
  checkHasSectionData,
  getCreditActivitySummary,
  getValueFromNameValueArray,
} from './utils';
import styles from './summary-report-styles.css';
import { ICreditActivitySummary } from './types';
import { SectionContentContainer } from '../styles';

const CreditActivitySummaryContent = ({
  creditActivitySummary,
}: {
  creditActivitySummary: ICreditActivitySummary;
}) => {
  const { Enquiries, Judgements, Defaults } = creditActivitySummary || {};
  return (
    <SectionContentContainer>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}></th>
            <th className={styles.th + ' ' + styles.col_5}>Months</th>
            <th className={styles.col_4}></th>
          </tr>
        </tbody>
      </table>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Summary Type</th>
            <th className={styles.th + ' ' + styles.col_1}>Total</th>
            <th className={styles.th + ' ' + styles.col_1}>0-12</th>
            <th className={styles.th + ' ' + styles.col_1}>13-24</th>
            <th className={styles.th + ' ' + styles.col_1}>25-36</th>
            <th className={styles.th + ' ' + styles.col_1}>37+</th>
            <th className={styles.col_4}></th>
          </tr>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.col_3}>
              Enquiries Summary
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Enquiries, 'Total') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Enquiries, '0-12') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Enquiries, '13-24') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Enquiries, '25-36') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Enquiries, '37+') || '-'}
            </td>
            <th className={styles.col_4}></th>
          </tr>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.col_3}>
              Judgements Summary
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Judgements, 'Total') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Judgements, '0-12') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Judgements, '13-24') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Judgements, '25-36') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Judgements, '37+') || '-'}
            </td>
            <th className={styles.col_4}></th>
          </tr>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.col_3}>Defaults Summary</td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Defaults, 'Total') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Defaults, '0-12') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Defaults, '13-24') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Defaults, '25-36') || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_1}>
              {getValueFromNameValueArray(Defaults, '37+') || '-'}
            </td>
            <th className={styles.col_4}></th>
          </tr>
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const CreditActivitySummary = ({ isAllExpanded, data }) => {
  const creditActivitySummary: ICreditActivitySummary =
    getCreditActivitySummary(data);
  const hasSectionData = checkHasSectionData(creditActivitySummary);

  return (
    <ReviewContainer
      subHeading="Credit Activity Summary"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CreditActivitySummaryContent
          creditActivitySummary={creditActivitySummary}
        />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default CreditActivitySummary;
