/* Identity section constants */
export const IDENTITY_SECTION_COMPLETE = 'IDENTITY_SECTION_COMPLETE';
export const IDENTITY_COMPONENT_COMPLETE = 'IDENTITY_COMPONENT_COMPLETE';
export const IDENTITY_SET_FIRSTNAME = 'IDENTITY_SET_FIRSTNAME';
export const IDENTITY_SET_LASTNAME = 'IDENTITY_SET_LASTNAME';
export const IDENTITY_SET_MIDDLENAME = 'IDENTITY_SET_MIDDLENAME';
export const IDENTITY_SET_DOB = 'IDENTITY_SET_DOB';

export const IDENTITY_SET_ADDRESS = 'IDENTITY_SET_ADDRESS';
export const IDENTITY_SELECT_ADDRESS = 'IDENTITY_SELECT_ADDRESS';
export const IDENTITY_SET_PROOF_OF_ADDRESS = 'IDENTITY_SET_PROOF_OF_ADDRESS';
export const IDENTITY_ADDRESS_LOOKUP_CLEAR = 'IDENTITY_ADDRESS_LOOKUP_CLEAR';
export const IDENTITY_ADDRESS_LOOKUP_START = 'IDENTITY_ADDRESS_LOOKUP_START';
export const IDENTITY_ADDRESS_LOOKUP_SUCCESS =
  'IDENTITY_ADDRESS_LOOKUP_SUCCESS';
export const IDENTITY_ADDRESS_LOOKUP_ERROR = 'IDENTITY_ADDRESS_LOOKUP_ERROR';

export const IDENTITY_SET_TYPE = 'IDENTITY_SET_TYPE';
export const IDENTITY_SET_NUMBER = 'IDENTITY_SET_NUMBER';
export const IDENTITY_SET_DRIVER_LICENCE_VERSION =
  'IDENTITY_SET_DRIVER_LICENCE_VERSION';
export const IDENTITY_SET_IDENTIFICATION_EXP_DATE =
  'IDENTITY_SET_IDENTIFICATION_EXP_DATE';
export const IDENTITY_SET_DRIVER_LICENCE_STATE =
  'IDENTITY_SET_DRIVER_LICENCE_STATE';
export const IDENTITY_SET_IMAGE_START = 'IDENTITY_SET_IMAGE_START';
export const IDENTITY_SET_IMAGE_SUCCESS = 'IDENTITY_SET_IMAGE_SUCCESS';
export const IDENTITY_SET_IMAGE_ERROR = 'IDENTITY_SET_IMAGE_ERROR';

export const IDENTITY_SET_IMAGE_64 = 'IDENTITY_SET_IMAGE_64';
export const IDENTITY_SET_IMAGE_64_NAME = 'IDENTITY_SET_IMAGE_64_NAME';

export const IDENTITY_SET_BACK_IMAGE_64 = 'IDENTITY_SET_BACK_IMAGE_64';
export const IDENTITY_SET_BACK_IMAGE_64_NAME =
  'IDENTITY_SET_BACK_IMAGE_64_NAME';

export const IDENTITY_SET_NOIDENTIFICATION = 'IDENTITY_SET_NOIDENTIFICATION';
export const IDENTITY_SET_NOIDENTIFICATION_REASON =
  'IDENTITY_SET_NOIDENTIFICATIONSON_REASON';
export const IDENTITY_SET_CAMERA = 'IDENTITY_SET_CAMERA';

export const IDENTITY_SET_REGION = 'IDENTITY_SET_REGION';
export const IDENTITY_SET_OTHER_REGION = 'IDENTITY_SET_OTHER_REGION';

export const IDENTITY_CLEAR_STATE = 'IDENTITY_CLEAR_STATE';
export const IDENTITY_SCRAPE_IMAGE_START = 'IDENTITY_SCRAPE_IMAGE_START';
export const IDENTITY_SCRAPE_IMAGE_END = 'IDENTITY_SCRAPE_IMAGE_END';
export const IDENTITY_SET_IMAGE_FILE_DETAILS =
  'IDENTITY_SET_IMAGE_FILE_DETAILS';
export const IDENTITY_SET_MOCK_PERCENTAGE = 'IDENTITY_SET_MOCK_PERCENTAGE';

export const IDENTITY_SELECT_NO_IMAGE_FILE = 'IDENTITY_SELECT_NO_IMAGE_FILE';
export const IDENTITY_SET_NAME = 'IDENTITY_SET_NAME';
export const IDENTITY_SET_NAMES_LOCKED = 'IDENTITY_SET_NAMES_LOCKED';
export const IDENTITY_SELECT_PDF_TOO_LONG = 'IDENTITY_SELECT_PDF_TOO_LONG';

export const SET_IDENTITY = 'SET_IDENTITY';

export const IDENTITY_CLICK_EVENT = 'IDENTITY_CLICK_EVENT';
export const IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY =
  'IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY';
export const IDENTITY_SET_FRONT_FACE_IMAGE = 'IDENTITY_SET_FRONT_FACE_IMAGE';

export const IDENTITY_SET_ANTI_FRAUD_RESULT = 'IDENTITY_SET_ANTI_FRAUD_RESULT';
export const IDENTITY_SET_IDENTIFICATION_CARD_NUMBER =
  'IDENTITY_SET_IDENTIFICATION_CARD_NUMBER';

export const IDENTITY_SET_AUTHENTICITY_SCORE =
  'IDENTITY_SET_AUTHENTICITY_SCORE';

export const IDENTITY_SET_PROOF_OF_LIFE_IMAGE =
  'IDENTITY_SET_PROOF_OF_LIFE_IMAGE';

export const IDENTITY_SET_PROOF_OF_LIFE_MAX_RETRY_REACHED =
  'IDENTITY_SET_PROOF_OF_LIFE_MAX_RETRY_REACHED';

export const IDENTITY_SET_RESIDENTIAL_TYPE = 'IDENTITY_SET_RESIDENTIAL_TYPE';
export const IDENTITY_SET_COUNTRY_CODE = 'IDENTITY_SET_COUNTRY_CODE';
export const IDENTITY_SET_PHONE_NUMBER = 'IDENTITY_SET_PHONE_NUMBER';
