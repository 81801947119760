import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getAccountPaymentInfo } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';

const AccountPaymentInfoContents = ({ accountPaymentInfo }) => {
  return (
    <SectionContentContainer>
      <table className={styles.table_free}>
        <thead>
          <tr className={styles.row_free}>
            <th className={styles.th_free}>Year</th>
            <th className={styles.th_free}>Month</th>
            <th className={styles.th_free}>Worst Payment Status</th>
            <th className={styles.th_free}>Aggregated Balance</th>
            <th className={styles.th_free}>Current Spend</th>
            <th className={styles.th_free}>Current Due</th>
            <th className={styles.th_free}>Period 1</th>
            <th className={styles.th_free}>Period 2</th>
            <th className={styles.th_free}>Period 3</th>
            <th className={styles.th_free}>Period 4</th>
            <th className={styles.th_free}>Period 5</th>
            <th className={styles.th_free}>Period 6</th>
            <th className={styles.th_free}>Period 7+</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </SectionContentContainer>
  );
};

const AccountPaymentInformation = ({ isAllExpanded, data }) => {
  const accountPaymentInfo = getAccountPaymentInfo(data);
  const hasSectionData = checkHasSectionData(accountPaymentInfo);

  return (
    <ReviewContainer
      subHeading="Account Payment Information"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <AccountPaymentInfoContents accountPaymentInfo={accountPaymentInfo} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default AccountPaymentInformation;
