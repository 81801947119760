import WebCamera from 'react-webcam';
import styled, { css } from 'styled-components';

export const Webcam = styled(WebCamera)`
  position: relative;
`;

export const CameraFrame = styled.div`
  align-items: center;
  background: white;
  border: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2rem;
  position: relative;
  width: 100%;
`;

export const CameraReadyMessage = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

export const CameraCaptureWrapper = styled.div`
  bottom: 2rem;
  display: flex;
  left: 0;
  justify-content: center;
  margin: auto;
  position: absolute;
  right: 0;
`;

export const ErrorMsg = styled.p`
  margin-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
