import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import { FEATURE_FLAGS } from 'conf';
import { capitalize } from 'lodash';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import * as CreditCheckComponents from 'modules/applications/components/credit-checks';
import VedaCommercialCheck from 'modules/applications/components/VedaCommercialCheck';
import VedaConsumerCheck from 'modules/applications/components/VedaConsumerCheck';
import VedaLegacyCheck from 'modules/applications/components/VedaLegacyCheck';
import VedaV2CommercialCheck from 'modules/applications/components/VedaV2CommercialCheck';
import styles from 'modules/new-applications/css/CreditCheck.css';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import Button from 'modules/shared/components/inputs/Button.js';
import { canUserReviewCreditChecks } from 'modules/shared/helpers/currentUserHelper';
import { TabScrollButton } from 'mui-latest';
import Tab from 'mui-latest/Tab';
import Tabs from 'mui-latest/Tabs';
import React, { Fragment, useState } from 'react';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const VEDA_DEFAULT_PAGE_SIZE = 10;

const CustomTabScrollButton = withStyles((_theme) => {
  return {
    root: {
      '&.Mui-disabled': {
        width: 0,
      },
      overflow: 'hidden',
      transition: 'width 0.5s',
      width: 28,
    },
  };
})(TabScrollButton);

function InitiatingCreditCheck() {
  return (
    <div className={styles.initiating_credit_check}>
      <CircularProgress size={20} style={{ marginRight: '10px' }} />
      Initiating credit check
    </div>
  );
}

function mixPanelType(application, creditCheckType) {
  if (creditCheckType === 'company') {
    return 'company';
  }

  if (
    application.legalType === 'sole_trader' ||
    application.isPersonalIndividual
  ) {
    return 'applicant';
  }

  return 'person';
}

function trackMixpanel({ application, creditCheckType, currentUser, person }) {
  const type = mixPanelType(application, creditCheckType);

  const creditCheckRule =
    application.getSelectedCreditCheckNameByType(creditCheckType);

  mixpanel.track(`Apply Credit Check - ${type} - ${creditCheckRule}`, {
    Application: application.id,
    'Entity ID': get(currentUser, 'currentEntity.id'),
    'Signature ID': (person || {}).signatureId,
    distinct_id: currentUser.id,
  });
}

function onClickCheckbox({
  application,
  creditCheck,
  creditCheckType,
  currentUser,
  person,
  onUpdateApplicationState,
  setCreditCheckErrorMessages,
  corporateTrustId,
}) {
  trackMixpanel({ application, creditCheckType, currentUser, person });

  application.runCreditCheck({
    creditCheck,
    creditCheckType,
    currentUser,
    onSuccess: (response, creditCheck) => {
      if (!response.data['errors']) {
        return;
      }
      creditCheck.fatal();
      const errorMessage = responseToErrorMessage(response);
      setCreditCheckErrorMessages([
        {
          message: errorMessage,
          person_id: person.id,
        },
      ]);
    },
    person,
    corporateTrustId,
  });
  onUpdateApplicationState();
}

function responseToErrorMessage(response) {
  const errors = response.data.errors;
  const messages = errors.flatMap((error) => {
    switch (error.code) {
      case 'no_credit_check_rule':
        return [
          `Your credit check for ${error.params.full_name} isn’t available as credit checks for ${error.params.region} is disabled for this application.
        You can enable this option fore new applications in Settings > Business profile > Account rules.`,
        ];
      case 'credit_check_not_required':
        return ['Credit check is not required.'];
      case 'no_consumer':
        return ['There is no consumer.'];
      case 'application_not_ready':
        return ['The application is not ready for the credit check.'];
      default:
        return [];
    }
  });
  return messages.join('\n');
}

function checkPersonCanCreditcheck(person, applicationIncluded) {
  const signatures = applicationIncluded.filter(
    (included) => included.type === 'signatures'
  );

  const personSignature = signatures.find(
    (signature) => signature.id === person.signatureId
  );

  if (!personSignature) return false;

  const { attributes } = personSignature;
  const { first_name, last_name, dob, identification_details_visible } =
    attributes || {};
  const isSignatureTokenized =
    typeof identification_details_visible === 'boolean' &&
    identification_details_visible === false;
  return first_name && last_name && (dob || isSignatureTokenized);
}

function CreditCheckCheckbox(props) {
  const {
    application,
    currentUser,
    creditCheck,
    creditCheckType,
    person,
    onUpdateApplicationState,
    creditCheckErrorMessages,
    setCreditCheckErrorMessages,
    isCorporateTrustReport,
    corporateTrustReportData,
  } = props;
  const isChecked = isPresent(creditCheck) && creditCheck.canBeRendered;
  let isDisabled =
    isChecked ||
    application.status !== 'in_progress' ||
    get(currentUser, 'currentUserEntityLink.roleTypes').includes('standard');

  let personCanCreditCheck = true;

  if (creditCheckType === 'person') {
    personCanCreditCheck = checkPersonCanCreditcheck(
      person,
      application.included || []
    );

    if (!personCanCreditCheck) {
      isDisabled = true;
      creditCheck.statusDescription =
        'Unable to run credit check. Signature is not required for this authorisation type according to 1CAF settings.';
    }
  }

  if (creditCheck.isLoading) {
    return <InitiatingCreditCheck />;
  }

  const corporateTrustId = isCorporateTrustReport
    ? corporateTrustReportData.id
    : undefined;

  const onClick = () => {
    onClickCheckbox({
      application,
      creditCheck,
      creditCheckErrorMessages,
      creditCheckType,
      currentUser,
      onUpdateApplicationState,
      person,
      setCreditCheckErrorMessages,
      corporateTrustId,
    });
  };

  const markup = (content) => {
    return { __html: content };
  };

  const buttonLabel = creditCheck.isPersisted ? 'Rerun check' : 'Run check';

  if (person && person.id) {
    const creditCheckError = creditCheckErrorMessages.find(
      (item) => item.person_id === person.id
    );
    if (creditCheckError) {
      creditCheck.statusDescription = creditCheckError.message;
      isDisabled = true;
    }
  }

  return (
    <div className="mt-4 mb-2">
      <Button disabled={isDisabled} handleClick={onClick} text={buttonLabel} />
      <div
        className="mt-2"
        style={{
          color: 'black',
          fontFamily: 'Atlas Grotesk Web',
          fontSize: '12px',
        }}
        dangerouslySetInnerHTML={markup(creditCheck.statusDescription)}
      />
    </div>
  );
}

function CompanyCheckbox(props) {
  const {
    application,
    companyCreditCheck,
    isCorporateTrustReport,
    corporateTrustReportData,
  } = props;

  const originalChecks =
    application.legalType !== 'company' ||
    !application.isCompanyCreditCheckRequired;

  const isCorporateTrustFlowEnabled =
    FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

  const shouldHideCompanyCreditCheckButton =
    isCorporateTrustFlowEnabled && isCorporateTrustReport
      ? !isCorporateTrustReport
      : originalChecks;

  if (shouldHideCompanyCreditCheckButton) {
    return null;
  }

  let creditCheckType = 'company';
  let label = application.formattedBusinessName;

  if (isCorporateTrustReport) {
    creditCheckType = 'corporated_trustee';
    label = corporateTrustReportData.name;
  }

  return (
    <CreditCheckCheckbox
      creditCheck={companyCreditCheck}
      creditCheckType={creditCheckType}
      label={label}
      {...props}
    />
  );
}

function PersonCheckbox(props) {
  const { application, creditCheck, person } = props;
  if (!application.isPersonalCreditCheckRequired) {
    return null;
  }

  return (
    <CreditCheckCheckbox
      creditCheck={creditCheck}
      label={person.name}
      person={person}
      creditCheckType="person"
      {...props}
    />
  );
}

function VedaCommercialReport(props) {
  const { application, creditCheck } = props;

  if (creditCheck.isVersion2) {
    return (
      <VedaV2CommercialCheck
        data={creditCheck.attributes.data}
        application={application.attributes}
        default_page_size={VEDA_DEFAULT_PAGE_SIZE}
        check_attributes={creditCheck.attributes}
      />
    );
  }

  return (
    <VedaCommercialCheck
      data={creditCheck.attributes.data}
      application={application.attributes}
      default_page_size={VEDA_DEFAULT_PAGE_SIZE}
      check_attributes={creditCheck.attributes}
    />
  );
}

function VedaCheckReport(props) {
  const { creditCheck, person } = props;

  if (Array.isArray(creditCheck.data)) {
    return (
      <VedaLegacyCheck
        data={creditCheck.attributes.data}
        default_page_size={VEDA_DEFAULT_PAGE_SIZE}
      />
    );
  }

  if (creditCheck.serviceType === 'commercial') {
    return <VedaCommercialReport {...props} />;
  }

  return (
    <VedaConsumerCheck
      check_attributes={creditCheck.attributes}
      data={creditCheck.attributes.data}
      default_page_size={VEDA_DEFAULT_PAGE_SIZE}
      person={person}
    />
  );
}

function Report(props) {
  const { application, creditCheck, isVisible, entityType, isCompanyReport } =
    props;
  if (!isVisible || isBlank(creditCheck) || !creditCheck.canBeRendered) {
    return null;
  }

  if (creditCheck.modelType === 'veda_checks') {
    return (
      <FixedContent>
        <VedaCheckReport {...props} />
      </FixedContent>
    );
  }

  const CreditCheckComponent =
    CreditCheckComponents[`${creditCheck.type}Component`];

  return (
    <FixedContent>
      <CreditCheckComponent
        data={creditCheck.data}
        consumer={application.consumer}
        sequence_date={creditCheck.sequenceDate}
        pdf={creditCheck.pdf}
        signed_pdf={
          !isBlank(creditCheck.attributes)
            ? creditCheck.attributes.signed_pdf
            : ''
        }
        version={creditCheck.version}
        status={creditCheck.status}
        entityType={entityType}
      />
    </FixedContent>
  );
}

function CompanyReport(props) {
  const { application, isVisible, currentTab, creditCheckReport } = props;

  if (!application.isCompanyCreditCheckRequired || !isVisible) {
    return null;
  }

  const creditCheck = application.companyCreditCheck;

  return (
    <Fragment>
      {currentTab}
      <CompanyCheckbox creditCheck={creditCheck} {...props} />
      <Report creditCheck={creditCheck} isCompanyReport {...props} />
    </Fragment>
  );
}

// TEMP Remove: https://1centre.atlassian.net/browse/UXD-202
// function CorporateTrustReport(props) {
//   const { application, isVisible, currentTab, corporateTrustReportData } =
//     props;

//   if (
//     (!application.isCompanyCreditCheckRequired && !corporateTrustReportData) ||
//     !isVisible
//   ) {
//     return null;
//   }

//   const creditCheck = corporateTrustReportData.creditCheck;

//   const isCorporateTrustReport =
//     FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS; //force true prop if flag is on
//   return (
//     <Fragment>
//       {currentTab}
//       <CompanyCheckbox
//         isCorporateTrustReport={isCorporateTrustReport}
//         creditCheck={creditCheck}
//         {...props}
//       />
//       <Report
//         isCorporateTrustReport={isCorporateTrustReport}
//         creditCheck={creditCheck}
//         entityType="company"
//         {...props}
//       />
//     </Fragment>
//   );
// }

function PersonalReport(props) {
  const { isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <PersonCheckbox {...props} />
      <Report {...props} />
    </Fragment>
  );
}

function PersonalCreditChecks(props) {
  const { application, currentTab } = props;
  const creditChecks = application.creditChecks;

  let offset = 0;
  if (application.isCompanyCreditCheckRequired) {
    offset = 1;
  }

  // TEMP Remove: https://1centre.atlassian.net/browse/UXD-202
  // if (application.hasCorporateTrust) {
  //   offset = offset + application.corporateTrustees.length;
  // }

  return application.peopleToCreditCheck.map((person, index) => {
    const authorisation = application.authorisations.find(
      (authorisation) => authorisation.id === person.authorisationId
    );

    const tabIndex = index + offset;
    const isVisible = currentTab === tabIndex;

    const creditCheck =
      creditChecks.find(
        (check) => check.authorisationId === person.authorisationId
      ) || {};

    if (authorisation) {
      person['dob'] = get(authorisation, 'signature.dob', null);
    }

    return (
      <PersonalReport
        key={`personal-report-${index + 1}`}
        isVisible={isVisible}
        creditCheck={creditCheck}
        person={person}
        {...props}
      />
    );
  });
}

function CreditCheckTab(props) {
  return (
    <Tab
      classes={{
        root: styles.tab_root,
        wrapper: styles.tab_wrapper,
      }}
      {...props}
    />
  );
}

function getCompanyBusinessReportName(application) {
  if (['trust', 'partnership'].includes(application.legalType)) {
    return get(
      application,
      'consumer.companyName',
      capitalize(application.legalType)
    );
  }
  return 'Company';
}

function CreditCheckTabs(props) {
  const { application, currentTab, onChangeTab } = props;
  const tabs = [];
  let tabIndex = 0;

  if (application.isCompanyCreditCheckRequired) {
    tabs.push(
      <CreditCheckTab
        key="credit-check-tab-company"
        value={0}
        label="Company"
      />
    );
    tabIndex += 1;
  }

  // TEMP Remove: https://1centre.atlassian.net/browse/UXD-202
  // if (application.hasCorporateTrust) {
  //   application.corporateTrustees.forEach((corporateTrust) => {
  //     tabs.push(
  //       <CreditCheckTab
  //         key={'credit-check-tab-company-' + tabIndex}
  //         value={tabIndex}
  //         label={corporateTrust.entity_name}
  //       />
  //     );
  //     tabIndex += 1;
  //   });
  // }

  application.peopleToCreditCheck.forEach((person, index) => {
    const personalCreditCheckTab = tabIndex + index;
    tabs.push(
      <CreditCheckTab
        key={`credit-check-tab-${personalCreditCheckTab}`}
        value={personalCreditCheckTab}
        label={person.name}
      />
    );
  });

  return (
    <Paper square elevation={0}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={onChangeTab}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="credit check tabs"
        ScrollButtonComponent={CustomTabScrollButton}
      >
        {tabs}
      </Tabs>
    </Paper>
  );
}

function useTabState() {
  const [currentTab, setCurrentTab] = useState(0);
  const onChangeTab = (_, newTab) => setCurrentTab(newTab);

  return { currentTab, onChangeTab };
}

export default function CreditChecks(props) {
  const { currentUser, application } = props;
  const { currentTab, onChangeTab } = useTabState();
  const canReviewCreditChecks = canUserReviewCreditChecks(currentUser);
  const [creditCheckErrorMessages, setCreditCheckErrorMessages] = useState([]);

  // TEMP Remove: https://1centre.atlassian.net/browse/UXD-202
  //let corporateTrustCreditCheck = [];

  // if (application.hasCorporateTrust) {
  //   application.corporateTrustees.forEach((corporateTrust, index) => {
  //     const creditCheck =
  //       application.creditChecks.find(
  //         (creditCheck) => creditCheck.associatedEntityId === corporateTrust.id
  //       ) || {};

  //     corporateTrustCreditCheck.push({
  //       index: application.isCompanyCreditCheckRequired ? index + 1 : index,
  //       tabLabel: corporateTrust.entity_name,
  //       creditCheck,
  //       id: corporateTrust.id,
  //     });
  //   });
  // }

  return (
    <ScrollableContent>
      <FixedContent header="Credit checks">
        {!canReviewCreditChecks && (
          <div>
            Please contact your team admin for authorising access to credit
            checks function.
          </div>
        )}
        {canReviewCreditChecks && (
          <Fragment>
            <div>
              Once an individual signs and accepts your terms of trade you will
              be able to complete a credit check - simply click the 'Run check'
              button. Applicable charges will apply.
            </div>
            <CreditCheckTabs
              currentTab={currentTab}
              onChangeTab={onChangeTab}
              // TEMP Remove: https://1centre.atlassian.net/browse/UXD-202
              //corporateTrustCreditCheck={corporateTrustCreditCheck}
              {...props}
            />
            <CompanyReport
              isVisible={currentTab === 0}
              creditCheckErrorMessages={creditCheckErrorMessages}
              {...props}
            />
            {/*
              TEMP Remove: https://1centre.atlassian.net/browse/UXD-202
              {corporateTrustCreditCheck.map((creditCheckReport) => (
                <CorporateTrustReport
                  isVisible={creditCheckReport.index === currentTab}
                  creditCheckErrorMessages={creditCheckErrorMessages}
                  corporateTrustReportData={creditCheckReport}
                  {...props}
                />
              ))} */}
            <PersonalCreditChecks
              creditCheckErrorMessages={creditCheckErrorMessages}
              setCreditCheckErrorMessages={setCreditCheckErrorMessages}
              currentTab={currentTab}
              {...props}
            />
          </Fragment>
        )}
      </FixedContent>
    </ScrollableContent>
  );
}
